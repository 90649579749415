import styled from '@emotion/styled'
import { h9FontSize } from '../../appSkin.js'
import { Badge, Grid } from '@mui/material'

export const RegisterPolicyConsentSkin = styled.div`
	.h9-label span {
		${h9FontSize};
		font-family: Segoe UI;
		color: grey;
		text-align: justify;
	}

	span + span {
		display: block;
		margin-top: 1em; // Adds a top margin to the <p> element for spacing.
	}

	.overlay {
		position: relative;
	}
	
	.overlay::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
`
	

export const RequiredBadge = styled(Badge)`
	.MuiBadge-badge {
		background-color: #f50057; 
		color: white; // The color you want for the text
		padding: 12px; // Horizontal padding
		height: 20px; // Fixed height of the badge
				white-space: nowrap; // Prevent the text inside the badge from wrapping
		text-align: center; // Center the text inside the badge
		${h9FontSize};
		font-family: 'Segoe UI'; // The font family of the text
		line-height: 20px; // The line height of the text, should match the height of the badge for vertical centering
		
	}
`

export const CustomGridBadge = styled(Grid)`
	padding-bottom:0;
	`

export const CustomGridConsent = styled(Grid)`
	padding-top:0 !important;
	padding-bottom: 20px;
`

export const CustomGrid = styled(Grid)`
	padding-top: 0;
	padding-bottom: 0;
	margin-top:0;
`

