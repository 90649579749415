import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}payment`
})

export const createPayment = async ({ token, body }) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return await api.post('/serve', body, config)
}

export const paypalCheck = async ({ token, body }) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return await api.post('/suc', body, config)
}
