import styled from '@emotion/styled/macro'

export const AuthenticatedNavbarSkin = styled.div`
  .appBar {
    display: flex;
    align-items: center;
    padding: 0 30px;
    width: 100%;
    height: 95px;
    background-image: linear-gradient(
      180deg,
      #6a7ae9 0%,
      #4253c3 82%,
      #2335a5 100%
    );
  }
  .appBar ul {
    display: flex;
    justify-content: flex-end;
  }
  .logo {
    cursor: pointer;
    width: 120px;
  }
  .logo img {
    width: 100%;
  }
  .profile ul {
    display: flex;
    list-style: none;
    align-items: center;
  }
  .profile ul li {
    margin: 0 10px;
  }
  .profile ul li button {
    color: white;
    text-transform: capitalize;
  }
  .nav_link {
    width: 200px;
  }
.footer{
  background-image: linear-gradient(
    180deg, #6a7ae9 0%, #4253c3 82%, #2335a5 100%
  );
 
}
}

  @media (max-width: 600px) {
   
    .profile ul li {
      margin: 0 5px;
    }
    .appBar {
      padding: 0 10px;
      height: 70px;
    }
    
 
  }
  

  @media (max-width: 400px) {
    .appBar {
      padding: 0 10px;
      
    }
    
    .profile ul li {
      margin: 0 2px;
    }
  }
  .profile img {
    width: 100%;
  }
  .css-11lq3yg-MuiGrid-root {
    align-items: center !important;
  }

  @media (min-width:1500px){
    .logo {
      width: 120px;
    }
  }
  @media (max-width:600px){
    .logo {
      width: 80px !important;
    }
  }
 

`
