import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}user`
})

export const getUserById = async (id) => {
	return await api.get(`/${id}`)
}

export const getUserProfile = async (id) => {
	return await api.get(`/profile/${id}`)
}

export const changeUserEmail = async (id) => {
	// It's not working now
	// return await api.get(`/profile/${id}`);
}

export const updateProfile = async (id, userInfo) => {
	return await api.put(`/${id}`, { userInfo })
}

export const getSubscription = async (id) => {
	return await api.get(`/subscription/${id}`)
}

export const getReferral = async (id) => {
	return await api.get(`/referral/${id}`)
}

export const changeUserPassword = async (token, userInfo) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	}
	return await api.put(`/change-pass`, userInfo, config)
}

export const checkUserName = async (token) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	}
	return await api.get(`/check-name`, config)
}
