import { Fragment, useEffect, useState } from 'react'
import AuthNavbar from 'components/AuthNavbar'
import LoginFooter from 'components/LoginFooter'

import {
	LeftDiv,
	RightDiv,
	SubscriptionPageContainer
} from './subsciptionPageSkin'
import SideBar from 'shared/Sidebar'
import useAuth from 'hooks/useAuth'
import Subscription from 'components/Subscription'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Toast from 'shared/Toast'

const SubscriptionPage = () => {
	const { auth } = useAuth()
	const { t } = useTranslation()
	const { search } = useLocation()
	const searchParams = new URLSearchParams(search)
	const param = searchParams.get('payment')

	const [toastOpen, setToastOpen] = useState(false)
	const [toastMessage] = useState(t('cancelled_payment'))
	const [toastType] = useState('warning')

	const toastClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setToastOpen(false)
	}

	useEffect(() => {
		if (param === 'cancelled') {
			setToastOpen(true)
		}
	}, [param])

	return (
		<Fragment>
			<AuthNavbar />
			<SubscriptionPageContainer>
				<LeftDiv>
					<SideBar />
				</LeftDiv>

				<RightDiv>
					<div className="contextArea">
						<div style={{ marginBottom: 0 }} className="top h4">
							Subscriptions
						</div>

						<div className="middle_area">
							<Subscription id={auth.user.id} />
						</div>

						<div className="bottom" />
					</div>
				</RightDiv>
			</SubscriptionPageContainer>

			<LoginFooter />

			<Toast
				toastClose={toastClose}
				open={toastOpen}
				severity={toastType}
				message={toastMessage}
			/>
		</Fragment>
	)
}

export default SubscriptionPage
