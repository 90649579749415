import useAuth from 'hooks/useAuth'
import LoginFooter from 'components/LoginFooter'
import { ProfilePageContainer, LeftDiv, RightDiv } from './ReviewSkin'
import SideBar from 'shared/Sidebar'
import ReviewComponent from '../../components/Review/Review'
import AuthNavbar from 'components/AuthNavbar'
import { useEffect } from 'react'
import { getUserProfile } from 'api/user'
import { useState } from 'react'
import LoadingIndicator from 'components/LoadingIndicator'

const ReviewPage = () => {
	const { auth } = useAuth()
	const [userData, setUserData] = useState({})
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const getUser = async () => {
			try {
				const { data } = await getUserProfile(auth.user.id)
				setUserData(data)
				setIsLoading(false)
			} catch (error) {
				console.log('error')
			}
		}

		getUser()
	}, [auth.user.id])
	const { user_birthday } = userData
	return (
		<>
			{isLoading ? (
				<LoadingIndicator />
			) : (
				<>
					<AuthNavbar />
					<ProfilePageContainer>
						<LeftDiv>
							<SideBar />
						</LeftDiv>

						<RightDiv>
							<div className="contextArea">
								<div style={{ marginBottom: 0 }} className="top h4">
									Review
								</div>

								<div className="middle_area">
									<ReviewComponent userBirthDate={user_birthday} />
								</div>

								<div className="bottom" />
							</div>
						</RightDiv>
					</ProfilePageContainer>
					<LoginFooter />
				</>
			)}
		</>
	)
}

export default ReviewPage
