import React from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

class DeleteConfirm extends React.Component {
	constructor(props) {
		super(props)
		this.props = props
		this.state = {
			open: true
		}
	}

	render() {
		return (
			<div>
				<Dialog
					open={this.state.open}
					onClose={() => this.setState({ open: false })}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{'Delete Confirmation'}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Do you want to delete bookmark {this.props.title} ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.setState({ open: false })}>No</Button>
						<Button
							onClick={() => {
								this.setState({ open: false })
								this.props.onDelete()
							}}
							autoFocus
						>
							Yes
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}

export default DeleteConfirm
