import styled from '@emotion/styled/macro'

export const CommentModalForm = styled.form`
	padding: 3.5625% 3.5625% 0 3.5625%;
	background: #2a333a;
	color: #fff;
	overflow: hidden;
	// font-family: 'Segoe UI' !important;

	.font {
		font-family: 'Segoe UI';
	}
	.font-buttons {
		font-family: 'Segoe UI' !important;
	}
	//modal.header is the container for the title box and the close icon
	.modal_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 0%;
		margin-top: -1.5%;

		//Input box is the title box
		.input-box {
			border: 1px solid #fff;
			border-radius: 5px;
			background: transparent;
			width: 87.42%;
			margin: 1.6% 0;
			color: #fff;
			line-height: 2.5;
			margin-right: 3.22%;
			padding: 0 2%;
		}

		.close-icon {
			margin-left: 2.7%;
			cursor: pointer;
			background-color: #6a7ae9;
			border-radius: 5px;
		}

		.exit-icon {
			margin-left: 2.7%;
			cursor: pointer;
		}
	}
	//modal.middle is the KEYWORD input box
	.modal_middle {
		.keyword-box {
			border: 1px solid #fff;
			border-radius: 5px;
			background: transparent;
			width: 66.7%;
			margin: 2.6% 0;
			color: #fff;
			line-height: 2.5;
			margin-right: 3.22%;
			padding: 0 2%;
			/*@media screen and (min-width: 1100px) {
        width: 67.07%;
      }
      @media screen and (max-width: 800px) {
        width: 63.07%;
      }*/
		}
		//step.box is the step input box
		.step-box {
			border: 1px solid #fff;
			border-radius: 5px;
			background: transparent;
			width: 17.5%;
			padding: 0;
			color: #fff;
			padding: 0 2%;
			// text-align: center;
			line-height: 2.5;
			margin-right: 3.22%;
		}

		.step-box::-webkit-outer-spin-button,
		.step-box::-webkit-inner-spin-button {
			display: none;
		}
	}

	.modal_textarea {
		.top {
			width: 100%;
			height: 2.7%;
			background-image: linear-gradient(
				180deg,
				#2335a5 0%,
				#4253c3 29%,
				#6a7ae9 100%
			);
			border-radius: 8px 8px 0 0;
			margin-top: 1.3%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.bottom {
			width: 100%;
			height: 2.7%;
			background-image: linear-gradient(
				180deg,
				#6a7ae9 0%,
				#4253c3 71%,
				#2335a5 100%
			);
			border-radius: 0 0 8px 8px;
			margin-bottom: 2.7%;
			margin-top: -1%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.text-box {
			padding: 2% 2.7%;
			border: 1px solid #6a7ae9;
			background: transparent;
			width: 100%;
			resize: none;
			color: #fff;
			outline: none;
		}

		.scrollbar {
			overflow: hidden;
		}
	}

	.modal_footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;

		.step_container {
			display: flex;
			margin-bottom: 0;
			height: 100%;

			.step {
				display: flex;
				border-radius: 5px 5px 0 0;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				padding: 10px 15px;
				background: linear-gradient(to bottom, #394aba, #6a7ae9);
				color: #fff;
			}

			.selected_step {
				background: transparent;
				color: #fff;
			}
		}

		.discard_btn {
			margin-right: 15px !important;
		}
		.btn_container {
			display: flex;
			align-items: center;

			.action_btn {
				background-image: linear-gradient(
					180deg,
					#6a7ae9 0%,
					#4253c3 83%,
					#2335a5 100%
				);
				color: #fff;
				font-family: 'Segoe UI' !important;
				padding: 50 20.25%;
				height: 5.5%;
				outline: none;
				text-transform: capitalize !important;
				border: 0;
				border-radius: 5px;
				cursor: pointer;
				padding: 0px 21px;
			}
			@media screen and (max-width: 1024px) {
				.action_btn {
					padding: 6px 16px;
				}
			}
		}
	}

	.question-scrollbar::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.question-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`

export const Modal = styled.div`
	cursor: ${(props) => (!props.cursorStyle ? 'grab' : 'grabbing')};
`
