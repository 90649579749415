import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}auth`
})

export const register = async ({ email, action_id }) => {
	return await api.post('/register', { email, action_id })
}

export const login = async ({ email, password }) => {
	return await api.post('/login', { email, password })
}

export const confirmRegistration = async ({ id, name, email, password, consentIds }) => {
	return await api.post('/confirm-registration', { id, name, email, password, consentIds })
}

export const terminate = async ({ token, policyChange }) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	}
	return await api.put('/termination', { policyChange: policyChange }, config)
}

export const forgotPassword = async ({ email }) => {
	return await api.post('/forgot', { email })
}

export const resetPassword = async ({
	id,
	token,
	password,
	confirmPassword
}) => {
	return await api.post(`/reset/${id}/${token}`, { password, confirmPassword })
}
