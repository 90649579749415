//Libraries
import { useState, useRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { styled } from '@mui/material/styles'
import { Button, Grid } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import toast from 'react-hot-toast'
import Dialog from '@mui/material/Dialog'
import { useTranslation } from 'react-i18next'

//Images
import book from './../../images/book2.png'
import addBookBtn from './../../images/addbookBtn.png'

//Components
import { useStyles } from 'components/QnaPagePopup/style'
import './style.css'
import { AddBookModal, AsideBar } from './drawerSkin'

//APIs
import { addBook } from 'api/book'

//Hooks
import useAuth from 'hooks/useAuth'

//Utils
import { showToastWithVariableDuration } from '../../utils/toast'

//Root
import { queryClient } from 'App'
import { AppContainer } from '../../appSkin.js'

const BootstrapDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		width: '47.96875%'
	}
}))

const goUp = (id) => {
	id.scrollTo({
		top: 0,
		left: 0,
		behavior: 'smooth'
	})
}

const goDown = (id) => {
	id.scrollTo({
		top: id.scrollHeight,
		left: 0,
		behavior: 'smooth'
	})
}

const stopScroll = (id) => {
	id.scrollTop = id.scrollTop
}

const Drawer = ({ children, onReRender, handleDrawerToggle }) => {
	const ref = useRef()
	const { auth } = useAuth()

	const classes = useStyles()

	const middleRef = useRef()
	const asideContentRef = useRef()
	const asideRef = useRef()

	const [commentModal, setCommentModal] = useState(false)
	const [open, setOpen] = useState(false)
	const [file, setFile] = useState()
	const [bookTitle, setBookTitle] = useState('')
	const [author, setAuthor] = useState('')
	const [issn, setIssn] = useState('')
	const [note, setNote] = useState('')
	const [bookUploadLoading, setBookUploadLoading] = useState(false)
	const [visible, setVisible] = useState(false)
	const [maximize, setMaximize] = useState(false)

	const handleMaximize = () => {
		setMaximize(false)
		setCommentModal(true)
	}

	const thisOkay = (event) => {
		setFile()
		setVisible(true)
	}

	const thisNotOkay = (event) => {
		setFile(event.target.files[0])
		setVisible(false)
	}

	const handleFileChange = (event) => {
		event.target.files[0].size > 20970000 ? thisOkay(event) : thisNotOkay(event)
	}
	const { t } = useTranslation()

	const handleSubmit = async (event) => {
		event.preventDefault()

		if (file && bookTitle) {
			const formData = new FormData()
			formData.append('file', file)
			formData.append('bookTitle', bookTitle)
			formData.append('author', author)
			formData.append('issn', issn)
			formData.append('note', note)
			formData.append('user', auth.user.id)

			setBookUploadLoading(true)
			try {
				const response = await addBook({ formData }, auth.token)
				showToastWithVariableDuration(response.data.userMessage, 'success')
			} catch (error) {
				if (error.response) {
					showToastWithVariableDuration(error.response.data.message, 'error')
				} else if (error.request) {
					showToastWithVariableDuration(error.request, 'error')
				} else {
					showToastWithVariableDuration(error.message, 'error')
				}
			}

			setBookUploadLoading(false)
			queryClient.invalidateQueries('books-list')
			handleClose()
		} else if (!file) {
			toast.error('Mandatory params are missing!')
		} else {
			toast.error(t('book_title_required_field'))
		}
	}

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
		setBookTitle('')
		setAuthor('')
		setIssn('')
		setNote('')
		setFile()
	}

	function handleScrolling(role) {
		const middleEl = middleRef.current

		if (role === 'pre') {
			middleEl.scrollTop -= 50
		} else {
			middleEl.scrollTop += 50
		}
	}

	function handleAsideToggler() {
		const asideContentEl = asideContentRef.current
		const asideRefEl = asideRef.current
		if (asideContentEl.style.display === 'none') {
			asideContentEl.style.display = 'flex'
			asideRefEl.style.flexBasis = '29.018%'
			localStorage.setItem('closed', false)
			if (document.getElementsByClassName('control-right')[0])
				document.getElementsByClassName('control-right')[0].style.right = '17%'
			if (document.getElementsByClassName('control-left')[0])
				document.getElementsByClassName('control-left')[0].style.left = '24.5%'
			window.addEventListener('scroll', function () {})

			handleDrawerToggle('open')
		} else {
			asideContentEl.style.display = 'none'
			asideRefEl.style.flexBasis = '2%'
			if (document.getElementsByClassName('control-right')[0])
				document.getElementsByClassName('control-right')[0].style.right = '22%'
			if (document.getElementsByClassName('control-left')[0])
				document.getElementsByClassName('control-left')[0].style.left = '4%'
			localStorage.setItem('closed', true)
			handleDrawerToggle('closed')
		}
		onReRender()
	}

	return (
		<div ref={asideRef} className="sidebar drawer-sidebar" id="sidebar">
			<div ref={asideContentRef} className="aside_content">
				<div className="aside_top">
					<div className="top">
						<Grid container={true} alignItems="center" columns={10}>
							<Grid item={true} xs={3} className="bookImg">
								<img src={book} alt="" />
							</Grid>
							<Grid
								item={true}
								xs={7}
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<h1 className="h4">Library</h1>
							</Grid>
						</Grid>
					</div>

					<div className="middle">
						<button onClick={handleClickOpen}>
							<img src={addBookBtn} alt="" />
						</button>
					</div>
					<div className="modall">
						<BootstrapDialog
							onClose={handleClose}
							aria-labelledby="customized-dialog-title"
							open={open}
							maxWidth={false}
						>
							<AddBookModal onSubmit={handleSubmit}>
								<AppContainer>
									<div className="modal_top">
										<label className="custom-file-upload h8 font">
											<span>
												{file
													? file.name
													: 'CHOOSE A PDF FILE TO UPLOAD (<20 MB)'}
											</span>

											<span>
												<KeyboardArrowDownIcon />
											</span>

											<input
												type="file"
												accept="application/pdf"
												onChange={handleFileChange}
												id="file-type"
												name="file-type"
											/>
										</label>

										<span className="close-icon" onClick={handleClose}>
											<CloseIcon fontSize="large" />
										</span>
									</div>

									<input
										style={{ lineHeight: 2.5 }}
										className="h8 input-box font"
										type="text"
										id="title"
										name="title"
										placeholder="TITLE"
										value={bookTitle}
										onChange={(ev) => setBookTitle(ev.target.value)}
									/>

									<input
										style={{ lineHeight: 2.5 }}
										className="h8 input-box font"
										type="text"
										id="author"
										name="author"
										placeholder="AUTHOR"
										value={author}
										onChange={(ev) => setAuthor(ev.target.value)}
									/>

									<input
										style={{ lineHeight: 2.5 }}
										className="h8 input-box font"
										type="text"
										placeholder="ISSN / ISBN"
										value={issn}
										id="isbn"
										name="isbn"
										onChange={(ev) => setIssn(ev.target.value)}
									/>

									<div
										className="top"
										onPointerDown={(e) => goUp(ref.current)}
										onPointerUp={(e) => stopScroll(ref.current)}
									>
										<KeyboardArrowUpIcon style={{ cursor: 'pointer' }} />
									</div>

									<textarea
										ref={ref}
										id="text"
										rows="4"
										cols="50"
										maxLength="1000"
										className="h8 text-box font scrollbar"
										placeholder="YOUR NOTES"
										value={note}
										onChange={(ev) => setNote(ev.target.value)}
									/>

									<div
										className="bottom"
										onPointerDown={(e) => goDown(ref.current)}
										onPointerUp={(e) => stopScroll(ref.current)}
									>
										<KeyboardArrowDownIcon style={{ cursor: 'pointer' }} />
									</div>

									<div className="modal_bottom">
										{visible ? (
											<div className="h4 note1 font ">
												Please select a PDF file smaller than 20 Mb
											</div>
										) : null}
										<div className="note_buttons_container">
											<div className="h7 note font">
												{/* Please note that in most cases we can retrieve Metadata */}
												{/* directly from the uploaded file */}
											</div>
											<div className="buttons">
												<Button
													onClick={handleClose}
													variant="contained"
													className="login-btn h6"
												>
													Cancel
												</Button>
												<LoadingButton
													variant="contained"
													loading={bookUploadLoading}
													className="login-btn h6"
													disabled={bookUploadLoading}
													type="submit"
												>
													Save
												</LoadingButton>
											</div>
										</div>
									</div>
								</AppContainer>
							</AddBookModal>
						</BootstrapDialog>
					</div>

					<div className="bottom">
						<h4 className="h6">BOOKS:</h4>
						<Button onClick={() => handleScrolling('pre')} className="h6">
							<KeyboardArrowUpIcon />
						</Button>
					</div>
				</div>

				<div className="aside_middle" ref={middleRef}>
					{children}
				</div>

				<div className="aside_bottom">
					<Button onClick={() => handleScrolling('next')} className="h6">
						<KeyboardArrowDownIcon />
					</Button>
				</div>
			</div>

			<AsideBar>
				<Button onClick={handleAsideToggler}>
					{asideContentRef?.current?.style?.display === 'none' ? (
						<ArrowForwardIosIcon style={{ transform: 'none' }} />
					) : (
						<ArrowBackIosIcon />
					)}
				</Button>
			</AsideBar>

			{!commentModal && maximize && (
				<button className={classes.maximizeBtn} onClick={handleMaximize}>
					Maximize
				</button>
			)}
		</div>
	)
}

export default Drawer
