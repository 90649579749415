import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import payoffTwoImage from './../../images/Group 2907-2.png'
import { PayoffTwoLanding1Skin } from './skins'

const PayoffTwoLanding1 = () => {
	const { t } = useTranslation()

	return (
		<PayoffTwoLanding1Skin>
			<Grid container className="payoff-two-container">
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					className="payoff-top-row"
				/>
				<Grid
					item
					xs={0}
					sm={0}
					md={0}
					lg={6}
					sx={{ m: 'auto' }}
					display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}
				>
					<img src={payoffTwoImage} width={'100%'} height={'auto'} alt="" />
				</Grid>
				<Grid item xs={12} md={12} lg={6} sx={{ p: '1.5%' }}>
					<Typography variant="h2" align="left" className="payoff-two-title">
						{t('payoff_title')}
					</Typography>
					<Typography
						variant="h5"
						paragraph={true}
						align="left"
						className="payoff-two-text"
					>
						{t('payoff_desc')}
					</Typography>
				</Grid>
			</Grid>
		</PayoffTwoLanding1Skin>
	)
}

export default PayoffTwoLanding1
