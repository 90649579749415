import { Fragment, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import LoadingIndicator from 'components/LoadingIndicator'
import LoginFooter from 'components/LoginFooter'
import { paypalCheck } from 'api/payment'
import useAuth from 'hooks/useAuth'
import { PaymentDoneContainer } from './paymentDonePageSkin'
import { Button, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import LogoContainer from 'shared/LogoContainer'
import { useCallback } from 'react'

const PaymentDone = () => {
	const { auth } = useAuth()
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)

	const payerId = new URLSearchParams(window.location.search).get('PayerID')

	const paymentId = new URLSearchParams(window.location.search).get('paymentId')

	const history = useHistory()

	const paypalHandle = useCallback(
		async (payerId, paymentId) => {
			return await paypalCheck({
				token: auth.token,
				body: {
					payerId: payerId,
					paymentId: paymentId
				}
			})
		},
		[auth]
	)

	useEffect(() => {
		if (payerId && paymentId) {
			setLoading(true)
			paypalHandle(payerId, paymentId)
			const currentUserData = JSON.parse(sessionStorage.getItem('user') || '{}')
			currentUserData.expiration = false
			sessionStorage.setItem('user', JSON.stringify(currentUserData))
			setLoading(false)
			setTimeout(() => {
				history.push('/subscription')
			}, [500 * 60])
		}
	}, [payerId, paymentId, paypalHandle])

	return loading ? (
		<LoadingIndicator />
	) : (
		<Fragment>
			<PaymentDoneContainer>
				<Grid container className="login-container loginP" columns={36}>
					<Grid item xs={6} sm={9} lg={12} xl={12} />

					<Grid item xs={24} sm={18} lg={12} xl={9} align="center">
						<Link to="/">
							<LogoContainer />
						</Link>
						<div className="login-input-container">
							<Typography variant="h4" className="login-title h4">
								{/* {t('login_text')} */}
								{t('payment_confirmation')}
							</Typography>
							<br />
							<br />
							<Typography className="h7">
								<p className="pargraphs"> {t('payment_confirmation_p1')}</p>
								<br />
								<p className="pargraphs">
									{' '}
									{t('payment_confirmation_p2')}{' '}
								</p>{' '}
								<br />
								<p className="pargraphs"> {t('payment_confirmation_p3')}</p>
								<br />
							</Typography>

							<Typography variant="h4" className="login-title h4">
								{t('payment_success')}
							</Typography>

							<br />

							<Link to="/subscription">
								<div className="input-container">
									<Button variant="contained" className="login-btn h6">
										Go to the Subscriptions page!
									</Button>
								</div>
							</Link>
						</div>
					</Grid>
					<Grid item xs={6} sm={9} lg={12} xl={12} />
				</Grid>
				<br />
				<br />
			</PaymentDoneContainer>

			<LoginFooter />
		</Fragment>
	)
}

export default PaymentDone
