import React from 'react'
import { AlertBoxHTMLContainer } from './alertBoxHTMLSkin'
import {
	Button,
	DialogActions,
	DialogTitle,
	DialogContent,
	DialogContentText
} from '@mui/material'

//In this Alert component the "description" is expected to be in the form of HTML code, which will be enclosed in a div container
const AlertBoxHTML = ({
	description,
	open,
	setOpenAlertBox,
	setCancelAlertBox,
	title,
	btnText = 'OK',
	cancel = false
}) => {
	// const theme = useTheme();
	// const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<div>
			<AlertBoxHTMLContainer
				open={open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" className="alert-dialog-title">
					{title}
				</DialogTitle>
				<DialogContent
					id="alert-dialog-description"
					className="alert-dialog-description"
				>
					{description}
					<DialogContentText
						id="alert-dialog-description"
						className="alert-dialog-description"
					>
						{/* Moved {Description} from DialogContentText to DialogContent because the first enclose everything inside a p tag, while the latter in a div tag */}
						{/* {description} */}
						
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{cancel ? (
						<Button
							className="alert-dialog-button"
							style={{ color: '#777777' }}
							onClick={() => setCancelAlertBox(false)}
						>
							Cancel
						</Button>
					) : null}
					<Button
						className="alert-dialog-button"
						onClick={() => setOpenAlertBox(false)}
					>
						{btnText}
					</Button>
				</DialogActions>
			</AlertBoxHTMLContainer>
		</div>
	)
}

export default AlertBoxHTML
