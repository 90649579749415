export const findCommonSubstring = (string1, string2) => {
	const m = string1.length
	const n = string2.length
	const dp = Array(m + 1)
		.fill(null)
		.map(() => Array(n + 1).fill(0))

	let maxLength = 0
	let endIndex = 0

	for (let i = 1; i <= m; i++) {
		for (let j = 1; j <= n; j++) {
			if (string1[i - 1] === string2[j - 1]) {
				dp[i][j] = dp[i - 1][j - 1] + 1
				if (dp[i][j] > maxLength) {
					maxLength = dp[i][j]
					endIndex = i - 1
				}
			} else {
				dp[i][j] = 0
			}
		}
	}

	if (maxLength === 0) {
		return ''
	}

	return string1.substr(endIndex - maxLength + 1, maxLength)
}
