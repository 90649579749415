import React, { useState, useMemo } from 'react'
import { Dialog, styled } from '@mui/material'
import { Container, LoadingMessage } from './modalWrapperSkin'
import CloseIcon from '@mui/icons-material/Close'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import PDFRenderer from 'components/PDFRenderer'
import LoadingIndicator from 'components/LoadingIndicator'
import { useTranslation } from 'react-i18next'

// import PrivacyNoteModal from 'components/PrivacyNoteModal'
// import file1 from '../../assets/privacy_notice.pdf'

const BootstrapDialog = styled(Dialog)(({ width, height }) => ({
	'& .MuiPaper-root': {
		width,
		maxWidth: '100%',
		height,
		minWidth: width ? width : window.innerWidth > 1100 ? '48%' : '75%',

		textAlign: 'justify',
		overflowY: 'auto'
	}
}))

const ModalWrapper = ({
	isVisible,
	setIsVisible,
	file,
	isLoading,
	width,
	height
}) => {
	//Check what kind of file is passsed to the modal wrapper
	if (file !== undefined && file !== null) {
		// console.log('This is the file passed to the ModalWrapper=>', file)
	} else {
		// console.log('The file passed to the ModalWrapper is UNDEFINED or NULL')
	}

	const { t } = useTranslation()

	const [renderPdf, setRenderPdf] = useState(false)

	// Trigger PDF rendering when modal becomes visible and reset when it closes

	const handlePDFClick = (event) => {
		if (event.target.tagName === 'A') {
			event.preventDefault()
			window.open(event.target.href, '_blank')
		}
	}

	// Close icon handler
	const handleCloseIconClick = () => {
		setIsVisible(false)
	}

	//Compute the width of the modal
	const { modalWidth, modalAbsoluteWidth } = useMemo(() => {
		const calculatedModalWidth = width
			? width
			: window.innerWidth > 1100
			? '48%'
			: '75%'

		const calculatedModalAbsoluteWidth =
			(parseFloat(calculatedModalWidth) / 100) * window.innerWidth
		return {
			modalWidth: calculatedModalWidth,
			modalAbsoluteWidth: calculatedModalAbsoluteWidth
		}
	}, [width])

	return (
		<BootstrapDialog
			aria-labelledby="customized-dialog-title"
			width={modalWidth}
			height={height}
			open={isVisible}
		>
			<Container>
				<div className="header">
					<h1> </h1>
					<CloseIcon
						style={{ cursor: 'pointer' }}
						onClick={handleCloseIconClick}
					/>
				</div>
				{isLoading ? (
					<>
						<LoadingMessage>{t('PDFLoading')}</LoadingMessage>
						<div className="loader">
							<LoadingIndicator />
						</div>
					</>
				) : (
					isVisible && (
						<div onClick={handlePDFClick}>
							<PDFRenderer file={file} modalWidth={modalAbsoluteWidth} />
							{/* <PrivacyNoteModal /> */}
						</div>
					)
				)}
			</Container>
		</BootstrapDialog>
	)
}

export default ModalWrapper
