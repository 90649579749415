import { createContext, useState } from 'react'

const PersonalDataContext = createContext({})

export const PersonalDataProvider = ({ children }) => {
	const [personalData, setPersonalData] = useState({})

	return (
		<PersonalDataContext.Provider value={{ personalData, setPersonalData }}>
			{children}
		</PersonalDataContext.Provider>
	)
}

export default PersonalDataContext
