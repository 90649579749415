import { Redirect } from 'react-router-dom'

// Dashboard
import Dashboard from '../pages/DashboardPage'
import Library from '../pages/LibraryPage'
import QnA from '../pages/QnAPage'
import Profile from '../pages/ProfilePage'
import Subscription from '../pages/SubscriptionPage'
import Referrals from '../pages/ReferralsPage'
import ChangePassword from '../pages/ChangePasswordPage'
import TerminateAccount from '../pages/TerminateAccountPage'

// Feature
import PaymentDone from '../pages/PaymentDonePage'

// Authentication
import LoginPage from '../pages/LoginPage'
import LoginPageClosedTesting from '../pages/LoginPageClosedTesting'
import SignUp from '../pages/SignUpPage'
import ForgotPassword from '../pages/ForgotPasswordPage'
import ResetPassword from '../pages/ResetPasswordPage'

// Home
import Home from '../pages/HomePage'
import PaymentCancelled from '../pages/PaymentCanelled'

// Review page
import ReviewPage from '../pages/ReviewPage/Review'
import EarlyRegistrationPage from '../pages/EarlyRegPage'

//Landing Pages
import HomeEarlyRegistration from '../landingPages/HPearlyRegistration'
import HomeOpenTesting from '../landingPages/HomePageOpenTesting'
import HomeClosedTesting from '../landingPages/HomePageClosedTesting'

const privateRoutes = [
	{ path: '/dashboard', component: Dashboard },
	{ path: '/library', component: Library },
	{ path: '/library/:bookId', component: Library },
	{ path: '/qna', component: QnA },
	{ path: '/qna/:qnaId', component: QnA },
	{ path: '/profile', component: Profile },
	{ path: '/subscription', component: Subscription },
	{ path: '/change-password', component: ChangePassword },
	{ path: '/referrals', component: Referrals },
	{ path: '/terminate', component: TerminateAccount},
	{ path: '/payment-done', component: PaymentDone },
	{ path: '/payment-cancelled', component: PaymentCancelled },
	{ path: '/review', component: ReviewPage },
	{ path: '/', exact: true, component: () => <Redirect to="/dashboard" /> }
]

// const publicRoutes = [
// 	{ path: '/', component: Home },
// 	{ path: '/mktg/pmuc01', component: HomeEarlyRegistration },
// 	{ path: '/mktg/pmuc02', component: HomeOpenTesting },
// 	{ path: '/mktg/pmuc03', component: HomeClosedTesting },
// 	{ path: '/login', component: LoginPage },
// 	{ path: '/loginCT', component: LoginPageClosedTesting },
// 	{ path: '/forgot-password', component: ForgotPassword },
// 	{ path: '/reset-password/:id/:token', component: ResetPassword },
// 	{ path: '/signup/:token', component: SignUp },
// 	{ path: '/mktg/early-register/:token', component: EarlyRegistrationPage }
// ]

const publicHomePageRoutes = [
	{ path: '/', component: Home },
	{ path: '/mktg/pmuc01', component: HomeEarlyRegistration },
	{ path: '/mktg/pmuc02', component: HomeOpenTesting },
	{ path: '/mktg/pmuc03', component: HomeClosedTesting },
]
const loginRoutes = [{ path: '/login', component: LoginPage }]

const loginCTRoutes = [{ path: '/loginCT', component: LoginPageClosedTesting }]

const geoRestrictedRoutes = [
	{ path: '/forgot-password', component: ForgotPassword },
	{ path: '/reset-password/:id/:token', component: ResetPassword },
	{ path: '/signup/:token', component: SignUp },
]

const earlyRegistrationRoutes = [
	{ path: '/mktg/early-register/:token', component: EarlyRegistrationPage }
]

export {
	privateRoutes,
	publicHomePageRoutes,
	loginRoutes,
	loginCTRoutes,
	geoRestrictedRoutes,
	earlyRegistrationRoutes
}
