import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Toaster } from 'react-hot-toast'

import NotFound from './pages/NotFoundPage'
import {
	PrivateRoute,
	PublicHomePageRoute,
	LoginRoute,
	LoginCTRoute,
	GeoRestrictedRoute,
	EarlyRegistrationRoute
} from './routes/route'
import {
	privateRoutes,
	publicHomePageRoutes,
	loginRoutes,
	loginCTRoutes,
	geoRestrictedRoutes,
	earlyRegistrationRoutes
} from './routes'

import { AppContainer } from './appSkin'

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true
		}
	}
})

const RouterComponent = () => {
	return (
		<Switch>
			{/* {publicRoutes.map((route, idx) => (
							<PublicRoute
								exact
								key={idx}
								path={route.path}
								component={route.component}
							/>
						))} */}

			{geoRestrictedRoutes.map((route, idx) => (
				<GeoRestrictedRoute
					exact
					key={idx}
					path={route.path}
					component={route.component}
				/>
			))}

			{earlyRegistrationRoutes.map((route, idx) => (
				<EarlyRegistrationRoute
					exact
					key={idx}
					path={route.path}
					component={route.component}
				/>
			))}

			{loginRoutes.map((route, idx) => (
				<LoginRoute
					exact
					key={idx}
					path={route.path}
					component={route.component}
				/>
			))}

			{loginCTRoutes.map((route, idx) => (
				<LoginCTRoute
					exact
					key={idx}
					path={route.path}
					component={route.component}
				/>
			))}

			{publicHomePageRoutes.map((route, idx) => (
				<PublicHomePageRoute
					exact
					key={idx}
					path={route.path}
					component={route.component}
				/>
			))}

			{privateRoutes.map((route, idx) => (
				<PrivateRoute
					exact
					key={idx}
					path={route.path}
					component={route.component}
				/>
			))}

			<Route component={NotFound} />
		</Switch>
	)
}
const App = () => {
	return (
		<AppContainer>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<RouterComponent />
				</BrowserRouter>

				<Toaster
					position="bottom-left"
					reverseOrder={false}
					toastOptions={{
						className: 'h7'
					}}
				/>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</AppContainer>
	)
}

export default App
