import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import {
	ChangePasswordContainer,
	LeftDiv,
	RightDiv
} from './changePasswordSkin'
import { Button, Grid, IconButton, Input, InputAdornment } from '@mui/material'
import Sidebar from 'shared/Sidebar'
import AuthNavbar from 'components/AuthNavbar'
import LoginFooter from 'components/LoginFooter'
import { changeUserPassword } from '../../api/user'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const ChangePassword = () => {
	const { t } = useTranslation()
	const [state, setState] = useState({
		current_password: '',
		new_password: '',
		confirm_password: ''
	})
	const [isUpdate, setIsUpdate] = useState('')
	const [showPasswordConfirmPass, setShowPasswordConfirmPass] = useState(false)
	const [showPasswordOldPass, setShowPasswordOldPass] = useState(false)
	const [showPasswordNewPass, setShowPasswordNewPass] = useState(false)
	const handleChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value })
	}

	const resetField = () => {
		setState({
			current_password: '',
			new_password: '',
			confirm_password: ''
		})
	}
	const handleSubmitPassword = async () => {
		// Call password change api here
		const token = sessionStorage.getItem('token')
		const payload = {
			currentPass: state.current_password,
			newPass: state.new_password,
			confirmPass: state.confirm_password
		}
		const response = await changeUserPassword(token, payload)
		if (response.status === 200) {
			resetField()
			setIsUpdate(true)
		}
	}

	const handleClickShowPasswordConfirmPass = () =>
		setShowPasswordConfirmPass((show) => !show)

	const handleMouseDownPasswordConfirmPass = (event) => {
		event.preventDefault()
	}

	const handleClickShowPasswordConfirmPassOldPass = () =>
		setShowPasswordOldPass((show) => !show)

	const handleMouseDownPasswordOldPass = (event) => {
		event.preventDefault()
	}
	const handleClickShowPasswordNewPass = () =>
		setShowPasswordNewPass((show) => !show)

	const handleMouseDownPasswordNewPass = (event) => {
		event.preventDefault()
	}
	return (
		<>
			<AuthNavbar />

			<ChangePasswordContainer>
				<LeftDiv>
					<Sidebar />
				</LeftDiv>

				<RightDiv>
					<div className="contextArea">
						<div style={{ marginBottom: 0 }} className="top h4">
							Change Password
						</div>

						<div className="middle_area">
							<Grid container="fluid">
								<div className="personalData">
									<p className="message h7">{t('change_password')}</p>

									<div className="private_data">
										<div className="left">
											<div className="inputfiled-container">
												<div className="labels h6">Current password</div>
												<Input
													name="current_password"
													id="current_password"
													onChange={(e) => handleChange(e)}
													className="h7"
													value={state.current_password}
													type={showPasswordOldPass ? 'text' : 'password'}
													style={{
														width: '100%',
														background: 'white',
														borderRadius: '10px'
													}}
													disableUnderline={true}
													inputProps={{
														style: {
															padding: '5.7%'
														}
													}}
													endAdornment={
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={
																	handleClickShowPasswordConfirmPassOldPass
																}
																onMouseDown={handleMouseDownPasswordOldPass}
															>
																{showPasswordOldPass ? (
																	<VisibilityOff />
																) : (
																	<Visibility />
																)}
															</IconButton>
														</InputAdornment>
													}
												/>
											</div>
											<div className="inputfiled-container">
												<div className="labels h6">New password</div>
												<Input
													name="new_password"
													id="new_password"
													onChange={(e) => handleChange(e)}
													className="h7"
													value={state.new_password}
													type={showPasswordNewPass ? 'text' : 'password'}
													style={{
														width: '100%',
														background: 'white',
														borderRadius: '10px'
													}}
													disableUnderline={true}
													inputProps={{
														style: {
															padding: '5.7%'
														}
													}}
													endAdornment={
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPasswordNewPass}
																onMouseDown={handleMouseDownPasswordNewPass}
															>
																{showPasswordNewPass ? (
																	<VisibilityOff />
																) : (
																	<Visibility />
																)}
															</IconButton>
														</InputAdornment>
													}
												/>
											</div>

											<div className="inputfiled-container">
												<div className="labels h6">Confirm new password</div>
												<Input
													className="h7"
													name="confirm_password"
													id="confirm_password"
													onChange={(e) => handleChange(e)}
													value={state.confirm_password}
													type={showPasswordConfirmPass ? 'text' : 'password'}
													style={{
														width: '100%',
														background: 'white',
														borderRadius: '10px'
													}}
													disableUnderline={true}
													inputProps={{
														style: {
															padding: '5.7%'
														}
													}}
													endAdornment={
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPasswordConfirmPass}
																onMouseDown={handleMouseDownPasswordConfirmPass}
															>
																{showPasswordConfirmPass ? (
																	<VisibilityOff />
																) : (
																	<Visibility />
																)}
															</IconButton>
														</InputAdornment>
													}
												/>
											</div>
											{isUpdate && (
												<p className="message h7">
													{t('password_update_successfully')}
												</p>
											)}
										</div>
									</div>
									<div className="actionButtons saveButton">
										<Button
											onClick={handleSubmitPassword}
											className="h4"
											variant="contained"
										>
											Save
										</Button>
									</div>
								</div>
							</Grid>
						</div>

						<div className="bottom" />
					</div>
				</RightDiv>
			</ChangePasswordContainer>

			<LoginFooter />
		</>
	)
}

export default ChangePassword
