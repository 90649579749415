import LoginFooter from 'components/LoginFooter/index'
import AuthNavbar from 'components/AuthNavbar'
import MainQnA from 'components/MainQnA'

const QnAPage = () => {
	return (
		<div
			style={{
				minHeight: '100vh',
				background: '#2A333A',
				fontFamily: 'Segoe UI'
			}}
		>
			<AuthNavbar />
			<MainQnA />
			<LoginFooter />
		</div>
	)
}

export default QnAPage
