import styled from '@emotion/styled/macro'
import { h4FontSize, h7FontSize, h8FontSize } from 'appSkin'
import AttachmentIcon from '@mui/icons-material/Attachment'

export const DialogueBox = styled.div`
	padding: 3.5625% 3.5625% 0 3.5625%;
	background: #2a333a;
	color: #fff;
	.header-text {
		${h7FontSize};
		color: #ffc000;
		font-family: 'Segoe UI';
	}
	.second-text {
		font-weight: bold;
		margin: 10px 0 20px 0;
	}
	.upload-images {
		width: 100%;
		// min-height: 50px;
		${h8FontSize};
		.error-message {
			color: red;
		}
	}
`
export const Form = styled.form`
	overflow: hidden;
	.modal-textarea {
		.top {
			width: 100%;
			height: 2.7%;
			background-image: linear-gradient(
				180deg,
				#2335a5 0%,
				#4253c3 29%,
				#6a7ae9 100%
			);

			border-radius: 8px 8px 0 0;
			margin-top: 1.3%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.bottom {
			width: 100%;
			height: 2.7%;
			background-image: linear-gradient(
				180deg,
				#6a7ae9 0%,
				#4253c3 71%,
				#2335a5 100%
			);
			border-radius: 0 0 8px 8px;
			margin-bottom: 2.7%;
			margin-top: -1%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.text-box {
			padding: 2% 2.7%;
			border: 1px solid #6a7ae9;
			background: transparent;
			width: 100%;
			resize: none;
			color: #fff;
			outline: none;
			${h8FontSize};
			font-family: 'Segoe UI';
		}

		.scrollbar {
			overflow: hidden;
		}
	}

	.modal-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2%;
		margin-top: 2%;

		.button {
			background-image: linear-gradient(
				180deg,
				#6a7ae9 0%,
				#4253c3 83%,
				#2335a5 100%
			);
			color: #fff;
			font-family: 'Segoe UI' !important;
			${h7FontSize};
			font-weight: bold;
			// padding: 50 20.25%;
			height: 50px;
			outline: none;
			text-transform: capitalize !important;
			border: 0;
			border-radius: 5px;
			cursor: pointer;
			@media screen and (max-width: 1100px) {
				height: 40px;
			}
			@media screen and (max-width: 900px) {
				height: 30px;
			}
		}
		.cancel-button {
			margin-right: 10px;
		}
		.loading-btn {
			color: transparent !important;
		}
		.Mui-disabled {
			cursor: not-allowed;
			pointer-events: initial;
		}
	}
`

export const StyledAttachmentIcon = styled(AttachmentIcon)`
	${h4FontSize};
	font-weight: bold;
	color: #fff;
	cursor: pointer;
`