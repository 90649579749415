import styled from '@emotion/styled/macro'

export const SubscriptionPopup = styled.div`
	background-image: url(${(props) => props.background});
	background-repeat: no-repeat;
	background-size: cover;
	color: white;

	.alert-dialog-title {
		display: flex;
		justify-content: space-between;
	}
	.dialog-title-text {
		margin-top: auto;
		margin-bottom: auto;
		font-size: 30px;
		width: 95%;
		text-align: center;
	}
	.alert-dialog-description {
		color: white;
		font-size: 20px;
		padding: 10px 0;
	}
	.button {
		background-color: #6a7ae9;
		color: #ffff;
		border: 1px solid;
		font-size: 18px;
	}
	.cross-icon {
		margin-top: 8px;
		color: #fff;
	}

	@media screen and (max-width: 1360px) and (max-height: 768px) {
		.dialog-title-text {
			font-size: 24px;
		}
		.alert-dialog-description {
			font-size: 17px;
		}
		.button {
			font-size: 15px;
		}
	}

	@media screen and (max-width: 1024px) {
		.dialog-title-text {
			font-size: 18px;
		}
		.alert-dialog-description {
			font-size: 13px;
		}
		.button {
			font-size: 12px;
		}
		.cross-icon {
			margin-top: 5px;
		}
	}

	@media screen and (max-width: 800px) {
		.dialog-title-text {
			font-size: 14px;
		}
		.alert-dialog-description {
			font-size: 10px;
		}
		.button {
			font-size: 12px;
		}
	}
`
