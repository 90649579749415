import styled from '@emotion/styled/macro'
import { Dialog } from '@mui/material'
import { h4FontSize, h7FontSize, h8FontSize } from '../../appSkin.js'
export const AlertBoxHTMLContainer = styled(Dialog)`
	& .MuiPaper-root {
		max-width: 50%;
	}

	.alert-dialog-description {
		${h7FontSize};
		font-family: Segoe UI;
		
		font-weight: 400;
		line-height: 1.5;
		color: rgba(0,0,0,0.6);

		p + p {
			display: block;
			margin-top: 1em; // Adds a top margin to the <p> element for spacing.
		}

		ul + p {
			display: block;
			margin-top: 1em; // Adds a top margin to the <p> element for spacing.
		}

		ul {
			list-style-type: none;
			padding-left: 2%; /*Bullets' indentation to the right of the above paragraph*/
		}

		li {
			position: relative;
			margin-left: 0;
			padding-left: 2%; /*Text indentation to the right of the bullet*/
		}

		li::before {
			content: '-'; /* Use a dash as a bullet point */
			position: absolute;
			left: 0; /* Align the dash to the left of the content */
		}
	}

	.alert-dialog-title {
		${h4FontSize}
	}

	.alert-dialog-button {
		font-weight: bold;
		${h8FontSize}
	}
`
