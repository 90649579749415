import { useTranslation } from 'react-i18next'

const NotFoundPage = () => {
	const { t } = useTranslation()
	return (
		<article>
			<h1>{t('notfound')}</h1>
		</article>
	)
}

export default NotFoundPage
