import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Typography
} from '@mui/material'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { resetPassword } from 'api/auth'

import logoImage from '../../images/logo.png'
import { isPasswordStrong } from '../../utils/isPasswordStrong'
import { PasswordRecoverySkin } from './skins'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

/* eslint-disable react/prefer-stateless-function */
const CreateNewPssword = ({ id, token }) => {
	const history = useHistory()

	const [pass, setPass] = useState('')
	const [loading, setLoadingState] = useState(false)
	const [showPass, setShowPass] = useState(false)
	const [showConPass, setShowConPass] = useState(false)
	const [confirmPass, setconfirmPass] = useState('')

	const resetPasswordHandler = async () => {
		if (pass !== confirmPass) {
			toast.error("Password doesn't match!")
		} else if (!isPasswordStrong(pass)) {
			toast.error('Password must contain 1 special character and 1 number!')
		} else {
			setLoadingState(true)

			try {
				const { data } = await resetPassword({
					id,
					token,
					password: pass,
					confirmPassword: confirmPass
				})

				if (data) {
					setLoadingState(false)
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'Password changed succesfully',
						text: `${data.message}`,
						showConfirmButton: false,
						timer: 2000
					})

					setTimeout(() => {
						history.push('/login')
					}, 2002)
				}
			} catch (error) {
				setLoadingState(false)
				if (error.response) {
					toast(error.response.data.message)
				} else if (error.request) {
					toast(error.request)
				} else {
					toast(error.message)
				}
			}
		}
	}

	const { t } = useTranslation()
	return (
		<PasswordRecoverySkin>
			<Grid
				container
				style={{ marginBottom: '40px' }}
				className="create_container"
				columns={36}
			>
				<Grid item xs={6} sm={9} lg={12} xl={12} />

				<Grid item xs={24} sm={18} lg={12} xl={9} align="center">
					<Link to="/">
						<img src={logoImage} className="logo-image" alt="logo" />
					</Link>
					<div className="create_pass_container">
						<Typography variant="h4" className="login-title h4">
							{t('create_new_pass')}
						</Typography>
						<p className="create_pass_p h7">{t('write_your_new_pass')}</p>
						<div className="input-container">
							<span className="create_pass_labels h6">{t('pwd')}</span>
							<OutlinedInput
								className="create_pass_input h7"
								id="outlined-adornment-password"
								name="outlined-adornment-password"
								type={showPass ? 'text' : 'password'}
								onInput={(e) => setPass(e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => setShowPass(!showPass)}
											edge="end"
										>
											{showPass ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</div>
						<div className="input-container">
							<span className="create_pass_labels h6">{t('confirm_pass')}</span>

							<OutlinedInput
								className="create_pass_input h7"
								id="outlined-adornment-password"
								name="outlined-adornment-password"
								type={showConPass ? 'text' : 'password'}
								onInput={(e) => setconfirmPass(e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => setShowConPass(!showConPass)}
											edge="end"
										>
											{showConPass ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</div>
						<div className="input-container">
							<Button
								onClick={resetPasswordHandler}
								variant="contained"
								className="login-btn h6"
							>
								{loading ? (
									<CircularProgress
										style={{ height: '28px', width: '28px' }}
										color="inherit"
									/>
								) : (
									t('saveNlogin')
								)}
							</Button>
						</div>
						<div width style={{ marginTop: '30px', maxWidth: '100%' }}>
							<h4 className="create_pass_h4 h6">{t('pass_required')}</h4>
							<p className="create_pass_p h7">{t('pass_requirements')}</p>
						</div>
					</div>
				</Grid>
				<Grid item xs={6} sm={9} lg={12} xl={12} />
			</Grid>
		</PasswordRecoverySkin>
	)
}

export default CreateNewPssword
