import React, { useState, useEffect, useRef } from 'react'
import { Grid, IconButton } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import RefreshIcon from '@mui/icons-material/Refresh'
import StopIcon from '@mui/icons-material/Stop'
import { CountdownContainer } from './countdownSkin'

// import audioFile to be used for the alarm sound
import alarmSound from '../../assets/sounds/12-days-funk.mp3'

import {
	setItemInLocalStorage,
	getItemFromLocalStorage
} from 'utils/localstorage'

const Countdown = () => {
	const [countdownValue] = useState(
		process.env.REACT_APP_DEFAULT_TIMER || '00:30:00'
	)
	const [running, setRunning] = useState(false)
	const [isTimerStopped, setIsTimerStopped] = useState(false)
	const [currentTime, setCurrentTime] = useState(countdownValue)
	const audioRef = useRef(null)
	useEffect(() => {
		let interval

		if (running) {
			interval = setInterval(() => {
				setCurrentTime((prevTime) => {
					const [hours, minutes, seconds] = prevTime.split(':').map(Number)

					if (hours === 0 && minutes === 0 && seconds === 0) {
						setIsTimerStopped(true)
						clearInterval(interval)
						// Handle flashing screen and alarm sound here
						audioRef.current.play()
						const body = document.querySelector('body')
						body.classList.toggle('flashing')
						// body.classList.add('flashing');
						return prevTime
					}

					let newSeconds = seconds - 1
					let newMinutes = minutes
					let newHours = hours

					if (newSeconds < 0) {
						newSeconds = 59
						newMinutes -= 1
					}

					if (newMinutes < 0) {
						newMinutes = 59
						newHours -= 1
					}
					const timer = `${String(newHours).padStart(2, '0')}:${String(
						newMinutes
					).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`
					const timerDetails = {
						isRunning: running,
						timer
					}
					setItemInLocalStorage('timer', JSON.stringify(timerDetails))
					return timer
				})
			}, 1000)
		} else {
			clearInterval(interval)
		}

		return () => clearInterval(interval)
	}, [running])

	useEffect(() => {
		const timerDetails = JSON.parse(getItemFromLocalStorage('timer'))
		if (timerDetails?.timer) {
			const { timer, isRunning } = timerDetails
			setCurrentTime(timer)
			setRunning(isRunning)
		}
	}, [])
	const handleReset = () => {
		resetCounter()
	}

	const resetCounter = () => {
		const body = document.querySelector('body')
		body.classList.remove('flashing')
		setCurrentTime(countdownValue)
		setRunning(false)
		const timerDetails = {
			timer: countdownValue,
			isRunning: false
		}
		setItemInLocalStorage('timer', JSON.stringify(timerDetails))
	}
	return (
		<CountdownContainer>
			<Grid container alignItems="center" justifyContent="center">
				<Grid item>
					<input
						type="text"
						className="h7"
						value={currentTime}
						disabled={running}
            id="timer"
            name="timer"
						onChange={(e) => setCurrentTime(e.target.value)}
					/>
				</Grid>
				<Grid item>
					<IconButton
						sx={{
							color: 'white'
						}}
						onClick={() => {
							if (isTimerStopped) {
								setIsTimerStopped(false)
								audioRef.current.pause()
								audioRef.current.currentTime = 0
								resetCounter()
							} else {
								setRunning(!running)
								const timerDetails = JSON.parse(
									getItemFromLocalStorage('timer')
								)
								const updatedTimerDetails = {
									...timerDetails,
									isRunning: !running
								}
								setItemInLocalStorage(
									'timer',
									JSON.stringify(updatedTimerDetails)
								)
							}
						}}
					>
						{isTimerStopped ? (
							<StopIcon />
						) : (
							<>{running ? <PauseIcon className="h7" /> : <PlayArrowIcon />}</>
						)}
					</IconButton>
					<IconButton
						onClick={handleReset}
						sx={{
							color: 'white'
						}}
					>
						<RefreshIcon />
					</IconButton>
				</Grid>
				<audio ref={audioRef} src={alarmSound} />
			</Grid>
		</CountdownContainer>
	)
}

export default Countdown
