import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}review`
})

export const getUserReview = async () => {
	const result = await api.get('/home')
	return result.data
}

export const getReviewByUserId = async (id) => {
	const result = await api.get(`/user/${id}`)
	return result.data
}

export const createReview = async ({ token, id, review }) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	}

	const response = await api.post(`/user/${id}`, review, config)
	return response
}

export const editReviewByUserId = async ({ token, id, review }) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	}

	const response = await api.put(`/user/${id}`, review, config)
	return response
}

export const deleteReview = async ({ token }) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	}

	const response = await api.delete(`/delete`, config)
	return response
}
