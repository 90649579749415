import { useState } from 'react'
import LibraryDisplay from '../LibraryDisplay'
import Drawer from '../Drawer'
import BookAccordion from '../BookAccordion'
import useAuth from 'hooks/useAuth'
import useBookNavigation from 'hooks/useBookNavigation'
import { LibraryContainer } from './mainLibrarySkin'

const MainLibrary = () => {
	const {
		auth: { token }
	} = useAuth()

	const {
		selectedBook,
		setSelectedBook,
		currentPage,
		setCurrentPage,
		nextPageNumber,
		setNextPageNumber,
		previousPageNumber,
		setPreviousPageNumber,
		bookTotalPage,
		setBookTotalPage,
		currentOpenBookId,
		setCurrentOpenBookId,
		nextPageHandle,
		setNextPageHandle,
		previousPageHandle,
		setPreviousPageHandle,
		handleOnChangePage,
		setHandleOnChangePage,
		refetchBookOnHandleChange,
		setRefetchBookOnHandleChange,
		activePageStatus
	} = useBookNavigation(token)

	const [key, setKey] = useState(0)

	const [onLoadBok, setOnLoadBook] = useState(true)
	const [drawerState, setDrawerState] = useState('open')
	const [updatedBookTitle, setUpdatedBookTitle] = useState('')

	const handleParentReRender = () => {
		setKey(key + 1) // Update the key value to trigger a re-render
	}

	const handleDrawerToggle = (currentState) => setDrawerState(currentState)

	return (
		<LibraryContainer>
			<Drawer
				onReRender={handleParentReRender}
				handleDrawerToggle={handleDrawerToggle}
			>
				<BookAccordion
					selectedBook={selectedBook}
					setSelectedBook={setSelectedBook}
					setNextPageHandle={setNextPageHandle}
					setPreviousPageHandle={setPreviousPageHandle}
					setOnLoadBook={setOnLoadBook}
					onLoadBok={onLoadBok}
					isBookUpdated={(updatedTitle) => setUpdatedBookTitle(updatedTitle)}
				/>
			</Drawer>
			<LibraryDisplay
				setOnLoadBook={setOnLoadBook}
				onLoadBook={onLoadBok}
				book={selectedBook}
				bookTitleUpdated={updatedBookTitle}
				setRefetchBookOnHandleChange={setRefetchBookOnHandleChange}
				refetchBookOnHandleChange={refetchBookOnHandleChange}
				currentPage={currentPage}
				currentOpenBookId={currentOpenBookId}
				setHandleOnChangePage={setHandleOnChangePage}
				setCurrentPage={setCurrentPage}
				setSelectedBook={setSelectedBook}
				setPreviousPageNumber={setPreviousPageNumber}
				setPreviousPageHandle={setPreviousPageHandle}
				setNextPageHandle={setNextPageHandle}
				setNextPageNumber={setNextPageNumber}
				setBookTotalPage={setBookTotalPage}
				setCurrentOpenBookId={setCurrentOpenBookId}
				nextPageNumber={nextPageNumber}
				bookTotalPage={bookTotalPage}
				activePageStatus={activePageStatus}
				drawerToggle={drawerState}
			/>
		</LibraryContainer>
	)
}

export default MainLibrary
