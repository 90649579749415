import { Grid, Box } from '@mui/material'
import { Fragment, useState } from 'react'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/css/modal-video.min.css'
import LoginFooter from '../../components/LoginFooter/index'
import Library from '../../components/Library'
import PrivatePageHeader from '../../components/AuthNavbar'
import QnA from '../../components/QnA'
import TutorialVideo from '../../components/TutorialVideoforUser'
import { UserSkins } from './skins'

const DashboardPage = () => {
	const [isOpen, setOpen] = useState(false)
	const [url, setUrl] = useState('')
	return (
		<Fragment>
			<PrivatePageHeader />
			<ModalVideo
				channel="youtube"
				width="5000"
				autoplay
				isOpen={isOpen}
				videoId={url}
				onClose={() => setOpen(false)}
			/>

			<Box
				sx={{
					background: '#2A333A',
					paddingTop: '3%',
					padding: 'unset 30px'
				}}
			>
				<UserSkins>
					<Grid container rowSpacing={3} columnSpacing={1}>
						<Grid item xs={12} sm={6}>
							<QnA />
						</Grid>
						<Grid item xs={12} sm={6}>
							<Library />
						</Grid>

						<Grid item xs={12}>
							<TutorialVideo modalData={{ isOpen, setOpen, setUrl }} />
						</Grid>
					</Grid>
				</UserSkins>
			</Box>
			<LoginFooter />
		</Fragment>
	)
}

export default DashboardPage
