import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
	modalBar: {
		justifyContent: 'center !important'
	},
	modalContainer: {
		// rowGap: "10px",
		marginTop: '0 !important'
	},

	emptyTableGridMobile: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '25%'
	},

	emptyTableGridWeb: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '10%'
	},

	top: {
		width: '100%',
		height: '15%',
		backgroundImage:
			'linear-gradient(\r\n    180deg,\r\n    #6a7ae9 0%,\r\n    #4253c3 83%,\r\n    #2335a5 100%\r\n  )',
		borderTopLeftRadius: '25px',
		borderTopRightRadius: '25px',
		marginTop: '1.3%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	bottom: {
		width: '100%',
		height: '15%',
		backgroundImage:
			'linear-gradient(\r\n    180deg,\r\n    #6a7ae9 0%,\r\n    #4253c3 83%,\r\n    #2335a5 100%\r\n  )',
		borderBottomLeftRadius: '25px',
		borderBottomRightRadius: '25px',
		marginBottom: '2.7%',
		marginTop: '-1%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},

	scrollbar: {
		overflow: 'hidden'
	},
	text_box: {
		padding: '2% 2.7%',
		border: '1px solid #6A7AE9',
		background: 'transparent',
		width: '100%',
		resize: 'none',
		color: '#fff',
		outline: 'none'
	},
	font: {
		// "fontFamily": "\"Times New Roman\" !important"
		fontFamily: 'Segoe UI'
	},
	ans_btn: {
		textAlign: 'center',
		borderRadius: '5%',
		color: '#fff',
		cursor: 'pointer',
		width: '80%'
	},

	btn_wrong: {
		background: '#be0f11'
	},

	btn_difficult: {
		background: '#502a5a'
	},

	btn_normal: {
		background: '#253481'
	},

	btn_easy: {
		background: '#5866b8'
	},

	closeIconCont: {
		alignSelf: 'center'
	},

	whiteColor: {
		color: '#ffff'
	},

	cursorPointer: {
		cursor: 'pointer'
	},

	cursorPointerNotAllowed: {
		cursor: 'not-allowed'
	},

	ans_btn_height: {
		marginTop: '-10px'
	},

	thBody: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 2%'
	},

	noWrap: {
		whiteSpace: 'nowrap'
	},

	stepInputWeb: {
		paddingLeft: '30px'
	},

	stepInputMobo: {
		paddingLeft: '25px'
	},

	gridContainer: {
		width: 'auto',
		margin: 'unset'
	},

	heading: {
		height: '60px'
	},

	minimizeBtn: {
		width: '1.086em',
		height: '1.086em'
	},

	minimizeBtnContainer: {
		width: '77.6%'
	},

	gridMargin: {
		marginTop: '3%'
	},

	fontP: {
		fontSize: '35px',
		color: '#fefefe'
	},

	iconDelEditUpdate: {
		height: 'auto',
		width: '100%'
	},

	note: {
		display: 'flex',
		flexDirection: 'column'
	},

	textAreaContainer: {},

	marginGrid: {
		marginTop: '2.64%'
	}

	//styling converted from the .CSS file and added here, but it does not work!
	//   qnaTable: {
	//     "& table": {
	//       fontFamily: "Segoe UI",
	//       color: "#fff",
	//       height: "288px",
	//       width: "100%",
	//       marginTop: "10px",
	//       display: "flex",
	//       justifyContent: "space-between",
	//       flexDirection: "column",
	//     },
	//     "& table thead": {
	//       flexBasis: "48px",
	//       width: "100%",
	//       display: "block",
	//       backgroundImage:
	//         "linear-gradient(180deg, #6a7ae9 0%, #4253c3 82%, #2335a5 100%)",
	//       borderRadius: "17px 17px 0 0",
	//     },
	//     "& table tbody": {
	//       flexGrow: 1,
	//       width: "100%",
	//       height: "192px",
	//       overflow: "auto",
	//       position: "relative",
	//       scrollBehavior: "smooth",
	//       transform: ".3s",
	//     },
	//     "& table tfoot": {
	//       flexBasis: "48px",
	//       width: "100%",
	//       display: "block",
	//       backgroundImage:
	//         "linear-gradient(180deg, #6a7ae9 0%, #4253c3 82%, #2335a5 100%)",
	//       borderRadius: "0 0 17px 17px",
	//     },
	//     "& table thead, & table tfoot": {
	//       display: "flex",
	//       alignItems: "center",
	//     },
	//     "& table thead tr, & table tfoot tr": {
	//       display: "flex",
	//       width: "100%",
	//       alignItems: "center",
	//       justifyContent: "space-between",
	//     },
	//     "& table thead tr td": {
	//       flexBasis: "15%",
	//       marginRight: "1%",
	//     },
	//     "& table thead tr td:nth-child(2)": {
	//       textAlign: "center",
	//     },
	//     "& table thead tr td button, & table tfoot tr td button": {
	//       padding: "0",
	//       margin: "0",
	//     },
	//     "& table thead tr td button svg, & table tfoot tr td button svg": {
	//       color: "white",
	//       fontSize: "37px",
	//     },
	//     "& table tbody tr": {
	//       width: "100%",
	//       display: "flex",
	//       justifyContent: "space-between",
	//       alignItems: "baseline",
	//       paddingBottom: "1.18%",
	//       paddingTop: "0.49%",
	//     },
	//     "& table tbody tr td": {
	//       height: "100%",
	//       display: "flex",
	//       alignItems: "center",
	//       marginRight: "1%",
	//       lineHeight: 0.9,
	//       gap: "6%",
	//     },
	//     "& table tbody tr:nth-child(odd)": {
	//       background: "#56657F",
	//     },
	//   },
	//   title: {
	//     color: "white",
	//     display: "flex",
	//     alignItems: "center",
	//   },
	//   "title svg": {
	//     fontSize: "inherit",
	//     color: "white",
	//     marginRight: "5%",
	//   },
	//   "qnaTable tr": {
	//     padding: "0 2%",
	//   },
	//   titleOpt: {
	//     display: "flex",
	//     justifyContent: "flex-end",
	//     color: "white",
	//   },
	//   "titleOpt span": {
	//     display: "inline-block",
	//     justifyContent: "flex-end",
	//     color: "white",
	//     marginLeft: "4%",
	//     width: "30px",
	//     textAlign: "center",
	//   },
	//   "titleOpt span:first-child": {
	//     marginLeft: "0",
	//   },
	//   "qnaTable table tbody tr td:last-child": {
	//     display: "flex",
	//     justifyContent: "flex-end",
	//   },
	//   "@media (max-width:600px)": {
	//     "qnaTable table thead, qnaTable table tfoot": {
	//       flexBasis: "35px",
	//     },
	//     "qnaTable table tbody tr": {
	//       height: "35px",
	//     },
	//   },
})
