import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCookie } from 'utils/common.util'
import { getLegalDocument } from 'api/legalDocument'
import { useQuery } from 'react-query'
import { ConsentContainer } from './consentModalSkin'
import ModalWrapper from 'components/ModalWrapper'
import { gtag } from 'utils/gtag'
import { SendPageViewEventToGTM }from '../../utils/gtag'


const ConsentModal = () => {
	const [isVisible, setIsVisible] = useState(true)
	const [isVisibleCookies, setIsVisibleCookies] = useState(false)

	const [consentProperties, setConsentProperties] = useState({
		functionality: false,
		necessary: true,
		measurement: false,
		marketing: false,
		experience: false
	})
	const { t } = useTranslation()
	
	const { isFetchingCookiesNotice, data: CookiesNoticeFile } = useQuery(
		['legal-documentation'],
		() => getLegalDocument('CookiesNotice'),
		{
			staleTime: 'Infinity',
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: false,
			enabled: isVisibleCookies
		}
	)
	const onAcceptAll = (e) => {
		e.preventDefault()
		e.stopPropagation()
		const consentMode = {
			functionality_storage: 'granted',
			security_storage: 'granted',
			ad_storage: 'granted',
			analytics_storage: 'granted',
			personalization_storage: 'granted',
			ad_personalization: 'granted',
			ad_user_data: 'granted' 
		}

		updateConsentMode(consentMode)
		
		//****************************************** */
		console.log('ConsentModal launches SendPageViewEventToGTM')
		//****************************************** */
		
		SendPageViewEventToGTM()
	}

	const onAcceptSelection = (e) => {
		const marketing = consentProperties.marketing ? 'granted' : 'denied'
		const consentMode = {
			functionality_storage: consentProperties.functionality
				? 'granted'
				: 'denied',
			security_storage: consentProperties.necessary ? 'granted' : 'denied',
			ad_storage: marketing,
			ad_personalization: marketing,
			ad_user_data: marketing,
			analytics_storage: consentProperties.measurement ? 'granted' : 'denied',
			personalization_storage: consentProperties.experience
				? 'granted'
				: 'denied',			 
		}
		updateConsentMode(consentMode)
		
		//****************************************** */
		console.log('ConsentModal launches SendPageViewEventToGTM')
		//****************************************** */

		SendPageViewEventToGTM()
		
	}

	const handleChange = (event) => {
		const { id, checked } = event.target
		setConsentProperties((prevState) => ({
			...prevState,
			[id.replace('consent-', '')]: checked
		}))
	}

	const onRejectAll = (e) => {
		const consentMode = {
			functionality_storage: 'denied',
			security_storage: 'denied',
			analytics_storage: 'denied',
			personalization_storage: 'denied',
			ad_storage: 'denied',
			ad_personalization: 'denied',
			ad_user_data: 'denied' 
		}
		updateConsentMode(consentMode)
	}

	const updateConsentMode = (consentMode) => {
		gtag('consent', 'update', consentMode)
		setCookieWithExpiration(
			'consentMode',
			JSON.stringify(consentMode),
			process.env.REACT_APP_GOOGLE_CONSENT_EXPIRATION_TIME
		)
		closeModal()
	}

	const setCookieWithExpiration = (name, value, expirationMonths) => {
		const now = new Date()
		now.setMonth(now.getMonth() + expirationMonths)
		const expirationDate = now.toUTCString()

		document.cookie = `${name}=${encodeURIComponent(
			value
		)}; expires=${expirationDate}; path=/`
	}

	const closeModal = () => {
		setIsVisible(false)
	}

	if (!isVisible) return null

	if (getCookie('consentMode')) {
		return null
	}
	return (
		<ConsentContainer>
			<div className="cookie-consent-banner">
				<h3>Cookie settings</h3>
				<p>
					{t('accept_all_cookies')}
					&nbsp;
					<span
						className="cookie-policy"
						onClick={() => setIsVisibleCookies(true)}
					>
						{t('cookie_policy')}
					</span>
					.
				</p>
				<button
					id="btn-accept-all"
					className="cookie-consent-button btn-success"
					onClick={onAcceptAll}
				>
					{t('accept_all')}
				</button>
				<button
					id="btn-accept-some"
					className="cookie-consent-button btn-outline"
					onClick={onAcceptSelection}
				>
					{t('accept_selection')}
				</button>
				<button
					id="btn-reject-all"
					className="cookie-consent-button btn-grayscale"
					onClick={onRejectAll}
				>
					{t('reject_all')}
				</button>
				<div className="cookie-consent-options">
					<label>
						<input
							id="consent-necessary"
							type="checkbox"
							value="Necessary"
							checked={consentProperties.necessary}
							disabled
						/>
						{t('necessary')}
					</label>
					<label>
						<input
							id="consent-functionality"
							type="checkbox"
							value="Functionality"
							checked={consentProperties.functionality}
							onChange={handleChange}
						/>
						{t('functionality')}
					</label>
					<label>
						<input
							id="consent-experience"
							type="checkbox"
							value="Experience"
							checked={consentProperties.experience}
							onChange={handleChange}
						/>
						{t('experience')}
					</label>
					<label>
						<input
							id="consent-marketing"
							type="checkbox"
							checked={consentProperties.marketing}
							value="Marketing"
							onChange={handleChange}
						/>
						{t('marketing')}
					</label>
					<label>
						<input
							id="consent-measurement"
							type="checkbox"
							value="Measurement"
							checked={consentProperties.measurement}
							onChange={handleChange}
						/>
						{t('measurement')}
					</label>
				</div>
				<ModalWrapper
					isVisible={isVisibleCookies}
					setIsVisible={setIsVisibleCookies}
					file={CookiesNoticeFile}
					isLoading={isFetchingCookiesNotice}
					width="50%"
					height="80%"
				/>
			</div>
		</ConsentContainer>
	)
}

export default ConsentModal
