import styled, { keyframes } from 'styled-components'

const blink = keyframes`
  0%, 49% {
    background-color: transparent;
  }
  50%, 100% {
    background-color: rgba(255, 255, 255, 0.5);
  }
`

export const CountdownContainer = styled.div`
	.flashing {
		animation: ${blink} 0.5s linear infinite;
	}

	input {
		font-family: 'Segoe UI';
		font-weight: bold !important;
		color: black;
		background-color: transparent;
		text-align: center;
		border: none;
		outline: none;
		width: 6em;
		color: white;
	}

	.MuiIconButton-root {
		font-size: 21px;
	}
`
