import React from 'react'
import { Dialog, styled } from '@mui/material'
import { Container } from './skins'
import CloseIcon from '@mui/icons-material/Close'

const BootstrapDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		width: '66%',
		height: '66%',
		textAlign: 'justify'
	}
}))

const HelpModal = ({ isVisible, setIsVisible }) => {
	return (
		<BootstrapDialog aria-labelledby="customized-dialog-title" open={isVisible}>
			{/*  */}
			<Container>
				<div className="WordSection1">
					<div className="header">
						<h1>
							<span lang="EN-GB">Help</span>
						</h1>
						<CloseIcon
							style={{ cursor: 'pointer' }}
							onClick={() => setIsVisible(false)}
						/>
					</div>

					<p className="MsoNormal">
						<span lang="EN-GB">Last updated:7/11/2023</span>
					</p>

					<p className="MsoNormal">
						<span lang="EN-GB">&nbsp;</span>
					</p>

					<p className="MsoNormal">
						<span lang="EN-GB">
							At{' '}
							<b>
								<a href="https://tutor.atlhas.com/">tutor.atlhas.com</a>
							</b>
							, we are committed to providing you with all the assistance
							necessary to enhance the effectiveness of your study practices.
						</span>
					</p>

					<p className="MsoNormal">
						<span lang="EN-GB">
							We've provided a series of tutorials accessible from your private dashboard to help you understand the best way to use our tools.
						</span>
					</p>
					<p className="MsoNormal">
						<span lang="EN-GB">
							Should you have any remaining questions or need further clarification, please do not hesitate to reach out to our support team by emailing <a href="mailto:support@tutor.atlhas.com?subject=REQUEST FOR ASSISTANCE">support@tutor.atlhas.com</a>	with the subject line "REQUEST FOR ASSISTANCE".
						</span>
					</p>
					<p className="MsoNormal">
						<span lang="EN-GB">
							While we strive to address all inquiries promptly, please be aware that emails without this specific subject line may not receive timely attention.
						</span>
					</p>
				</div>
			</Container>

			{/*  */}
		</BootstrapDialog>
	)
}

export default HelpModal
