import { useState, useRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { Dialog, Button, styled } from '@mui/material'
import { AppContainer } from 'appSkin'
import { CommentModalForm, Modal } from './commentModalSkin'
import { LoadingContainer } from 'components/LoadingIndicator/LoadingIndicator'
import Draggable from 'react-draggable'

//Import the library to be used with the translation service of i18nexus
import { useTranslation } from 'react-i18next'
import { makeInputUpperCase } from 'utils/common.util'

const BootstrapDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		width: '48.4375%'
	}
}))

const CommentModal = ({ open = false, onOk, data, canEdit, handleCancel }) => {
	const [isVisible, setIsVisible] = useState(open)
	const [step, setStep] = useState(1)
	const [keyword, setKeyword] = useState(data.note_keyword || '')
	const [sideOne, setSideOne] = useState(data.sideone || '')
	const [sideTwo, setSideTwo] = useState(data.sidetwo || '')
	const [stepInput, setStepInput] = useState(data.stepInput || '')
	const [isLoading, setIsLoading] = useState(false)
	const [isDraggable, setIsDraggable] = useState(false)
	const [cursorStyle, setCursorStyle] = useState(false)

	const submitButtonRef = useRef(null)

	const formSubmit = (ev) => {
		ev.preventDefault()

		setIsLoading(true)
		onOk({ title: data.title, keyword, sideOne, sideTwo, stepInput })
		setIsLoading(false)
		setIsVisible(false)
	}

	const onCancel = () => {
		setIsVisible(false)
		handleCancel()
	}

	// reference
	const ref = useRef()
	const keywordinput = useRef(null)

	const goUp = (id) => {
		id.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}

	const goDown = (id) => {
		id.scrollTo({
			top: id.scrollHeight,
			left: 0,
			behavior: 'smooth'
		})
	}

	const stopScroll = (id) => {
		id.scrollTop = id.scrollTop
	}

	//Call the translation service
	const { t } = useTranslation()

	//Retrieve variables from the translation service and assign them to constants
	const step1PlaceHolder = t('step1PlaceHolder')
	const step2PlaceHolder = t('step2PlaceHolder')
	const KEYWORD = t('keyword')
	const STEP = t('step')
	const tab1Name = t('tab1Name')
	const tab2Name = t('tab2Name')
	const saveButton = t('save')
	const cancelButton = t('cancel')

	const handleMouseDown = () => {
		// Set the cursor to "grabbing" when the mouse button is pressed
		setCursorStyle(true)
	}

	const handleMouseUp = () => {
		// Set the cursor back to "grab" when the mouse button is released
		setCursorStyle(false)
	}

	return (
		<Draggable
			disabled={isDraggable}
			onStart={handleMouseDown}
			onStop={handleMouseUp}
		>
			<BootstrapDialog
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						onCancel()
					}
				}}
				aria-labelledby="customized-dialog-title"
				open={isVisible}
				hideBackdrop={true}
			>
				<Modal cursorStyle={cursorStyle}>
					<CommentModalForm onSubmit={formSubmit} className="grab-hand">
						<AppContainer>
							<div className="modal_header">
								<input
									className="h8 input-box font"
									type="text"
									placeholder="TITLE"
									value={data.title}
									id="title"
									name="title"
									readOnly
									onMouseEnter={() => {
										setIsDraggable(true)
									}}
									onMouseLeave={() => {
										setIsDraggable(false)
									}}
								/>

								<span className="close-icon" onClick={onCancel}>
									<CloseIcon className="h4" />
								</span>
							</div>

							<div className="modal_middle">
								<input
									className="h8 keyword-box font"
									type="text"
									id="keyword-box"
									name="keyword-box"
									autoFocus={true}
									placeholder={KEYWORD}
									ref={keywordinput}
									value={keyword.toUpperCase()}
									disabled={canEdit ? false : true}
									onChange={(ev) => {
										setKeyword(makeInputUpperCase(keywordinput, ev))
									}}
									onMouseEnter={() => {
										setIsDraggable(true)
									}}
									onMouseLeave={() => {
										setIsDraggable(false)
									}}
								/>

								<input
									className="h8 step-box font"
									type="number"
									min={1}
									max={3}
									placeholder={STEP}
									id="step-box"
									name="step-box"
									value={stepInput}
									disabled={canEdit ? false : true}
									onChange={(ev) => {
										ev.preventDefault()
										if (parseInt(ev.target.value) > parseInt(ev.target.max)) {
											setStepInput(ev.target.max)
										} else {
											setStepInput(ev.target.value)
										}
									}}
									onMouseEnter={() => {
										setIsDraggable(true)
									}}
									onMouseLeave={() => {
										setIsDraggable(false)
									}}
								/>
							</div>

							<div className="modal_textarea">
								<div
									className="top"
									onPointerDown={(e) => goUp(ref.current)}
									onPointerUp={(e) => stopScroll(ref.current)}
								>
									<KeyboardArrowUpIcon style={{ cursor: 'pointer' }} />
								</div>

								{step === 1 ? (
									<textarea
										ref={ref}
										rows="7"
										cols="50"
										maxLength="3000"
										id="sideOne"
										name="sideOne"
										className="h8 text-box font question-scrollbar"
										placeholder={step1PlaceHolder}
										value={sideOne}
										disabled={canEdit ? false : true}
										onChange={(ev) => setSideOne(ev.target.value)}
										onMouseEnter={() => {
											setIsDraggable(true)
										}}
										onMouseLeave={() => {
											setIsDraggable(false)
										}}
										onKeyDown={(e) => {
											if (e.key === 'Tab') {
												e.preventDefault()
												setStep(2)
												ref.current.focus()
											}
										}}
									/>
								) : (
									<textarea
										ref={ref}
										rows="7"
										cols="50"
										maxLength="3000"
										id="sideOne"
										name="sideOne"
										className="h8 text-box font scrollbar"
										placeholder={step2PlaceHolder}
										value={sideTwo}
										disabled={canEdit ? false : true}
										onChange={(ev) => setSideTwo(ev.target.value)}
										onMouseEnter={() => {
											setIsDraggable(true)
										}}
										onMouseLeave={() => {
											setIsDraggable(false)
										}}
										onKeyDown={(e) => {
											if (e.key === 'Tab' && e.shiftKey) {
												e.preventDefault()
												setStep(1)
												ref.current.focus()
											}

											if (e.key === 'Tab' && !e.shiftKey) {
												e.preventDefault()
												submitButtonRef.current.focus()
											}
										}}
									/>
								)}

								<div
									className="bottom"
									onPointerDown={(e) => goDown(ref.current)}
									onPointerUp={(e) => stopScroll(ref.current)}
								>
									<KeyboardArrowDownIcon style={{ cursor: 'pointer' }} />
								</div>
							</div>

							<div className="modal_footer h6 font-buttons">
								<div className="step_container">
									<div
										className={`step ${step === 1 && 'selected_step'}`}
										onClick={() => setStep(1)}
									>
										{tab1Name}
									</div>
									<div
										className={`step ${step === 2 && 'selected_step'}`}
										onClick={() => setStep(2)}
									>
										{tab2Name}
									</div>
								</div>
								{step === 2 && canEdit && (
									<div className="btn_container font-buttons">
										<Button
											onClick={onCancel}
											variant="contained"
											className="h6 action_btn discard_btn"
										>
											{cancelButton}
										</Button>

										<Button
											ref={submitButtonRef}
											variant="contained"
											className="h6 action_btn"
											type="submit"
											disabled={canEdit ? false : true}
										>
											{isLoading ? <LoadingContainer /> : saveButton}
										</Button>
									</div>
								)}
							</div>
						</AppContainer>
					</CommentModalForm>
				</Modal>
			</BootstrapDialog>
		</Draggable>
	)
}

export default CommentModal
