import styled from '@emotion/styled/macro'

export const QARightSide = styled.div`
	display: flex;
	align-items: center;
	gap: 14px;

	span {
		cursor: pointer;
	}
`
