import { useState, useRef, Fragment, useEffect } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import LoadingIndicator from 'components/LoadingIndicator/index.js'
import QnaAccordion from 'components/QnaAccordion'
import Toast from 'shared/Toast'
import { getReviewList } from 'api/qna'
import useAuth from 'hooks/useAuth'
import useDeleteNote from '../../hooks/useDeleteNote'

const QnaDrawer = ({
	showQnaPopup,
	setShowQnaPopup,
	setSelectedBook,
	setBookTitles,
	expanded,
	setExpanded,
	hide,
	data,
	isLoading
}) => {
	const { auth } = useAuth()
	const middleRef = useRef()
	const asideContentRef = useRef()
	const asideRef = useRef()
	const [toastOpen, setToastOpen] = useState(false)
	const { noteDeted } = useDeleteNote()

	const { qnaId } = useParams()

	useEffect(() => {
		if (
			qnaId &&
			(expanded === undefined || expanded === null) &&
			data &&
			data.QAExpBookId.length > 0
		) {
			const fetchData = async () => {
				setExpanded(data.QAExpBookId.indexOf(qnaId))
				const result = await getReviewList(
					data.QAExpBookId[data.QAExpBookId.indexOf(qnaId)],
					auth.token
				)
				if (result) {
					setToastOpen(false)
					setSelectedBook(result)
					setBookTitles(data.QAExpBookTitle)
					setShowQnaPopup(true)
				}
			}
			fetchData()
		} else {
			// setShowQnaPopup(false)
		}
	}, [expanded, noteDeted, data])
	function handleScrolling(role) {
		const middleEl = middleRef.current

		if (role === 'pre') {
			middleEl.scrollTop -= 50
		} else {
			middleEl.scrollTop += 50
		}
	}

	function handleAsideToggler() {
		const asideContentEl = asideContentRef.current
		const asideRefEl = asideRef.current
		if (asideContentEl.style.display === 'none') {
			asideContentEl.style.display = 'flex'
			asideRefEl.style.flexBasis = '29.018%'
		} else {
			asideContentEl.style.display = 'none'
			asideRefEl.style.flexBasis = '2%'
		}
	}

	useEffect(() => {
		if (noteDeted && expanded !== undefined) {
			const refetchData = async () => {
				const result = await getReviewList(
					data.QAExpBookId[expanded],
					auth.token
				)
				if (result.LQARevId.length > 0) {
					setSelectedBook(result)
					setBookTitles(data.QAExpBookTitle)
					setShowQnaPopup(true)
				}
			}
			refetchData()
		}
	}, [noteDeted])

	const openReview = async () => {
		if (expanded === undefined) {
			setToastOpen(true)
		} else {
			const result = await getReviewList(data.QAExpBookId[expanded], auth.token)
			// const newNoteId = LQARevNoteId
			if (result) {
				setSelectedBook(result)
				setBookTitles(data.QAExpBookTitle)
				setShowQnaPopup(true)
			}
		}
	}

	const closeReview = () => {
		setShowQnaPopup(false)
		hide()
	}

	const toastClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setToastOpen(false)
	}

	return (
		<div ref={asideRef} className="sidebar">
			<div ref={asideContentRef} className="aside_content">
				<div className="aside_top">
					<div className="top">
						<Grid container alignItems="center" columns={10}>
							<Grid item xs={3} className="bookImg">
								<img src={'../../assets/QnA-page.png'} alt="" />
							</Grid>
							<Grid
								item
								xs={7}
								display="flex"
								alignItems="center"
								justifyContent="center"
							/>
						</Grid>
					</div>

					<div className="middle">
						{showQnaPopup ? (
							<button onClick={closeReview}>
								<img src={'../../assets/stop-button.png'} alt="stop button" />
							</button>
						) : (
							<button onClick={openReview}>
								<img src={'../../assets/go-button.png'} alt="go button" />
							</button>
						)}
					</div>

					{isLoading ? (
						<LoadingIndicator />
					) : (
						<div className="bottom">
							<h4 className="h6">BOOKS ({data.nQAExpAll})</h4>
							<Button onClick={() => handleScrolling('pre')} className="h6">
								<KeyboardArrowUpIcon />
							</Button>
						</div>
					)}
				</div>

				<div className="aside_middle" ref={middleRef}>
					{isLoading ? (
						<Fragment></Fragment>
					) : (
						<QnaAccordion
							QAExpBookTitle={data.QAExpBookTitle}
							nQAexpBook={data.nQAexpBook}
							expanded={expanded}
							setExpanded={setExpanded}
							open={showQnaPopup}
						/>
					)}
				</div>

				<div className="aside_bottom">
					<Button onClick={() => handleScrolling('next')} className="h6">
						<KeyboardArrowDownIcon />
					</Button>
				</div>
			</div>

			<div className="aside_bar">
				<Button onClick={handleAsideToggler}>
					<ArrowBackIosIcon />
				</Button>
			</div>

			<Toast
				toastClose={toastClose}
				open={toastOpen}
				severity="warning"
				message="Please select a book first"
			/>
		</div>
	)
}

export default QnaDrawer
