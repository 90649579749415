import { Fragment } from 'react'
import AuthNavbar from 'components/AuthNavbar'
import LoginFooter from 'components/LoginFooter'
import { LeftDiv, RightDiv, ReferralsPageContainer } from './referralsPageSkin'
import SideBar from 'shared/Sidebar'
import useAuth from 'hooks/useAuth'
import Referrals from 'components/Referrals'

const ReferralsPage = () => {
	const { auth } = useAuth()

	return (
		<Fragment>
			<AuthNavbar />
			<ReferralsPageContainer>
				<LeftDiv>
					<SideBar />
				</LeftDiv>

				<RightDiv>
					<div className="contextArea">
						<div style={{ marginBottom: 0 }} className="top h4">
							Referrals
						</div>

						<div className="middle_area">
							<Referrals id={auth.user.id} />
						</div>

						<div className="bottom" />
					</div>
				</RightDiv>
			</ReferralsPageContainer>

			<LoginFooter />
		</Fragment>
	)
}

export default ReferralsPage
