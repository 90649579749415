import LoginFooter from 'components/LoginFooter/index'
import MainLibrary from 'components/MainLibrary'
import AuthNavbar from 'components/AuthNavbar'
import { mainContainerStyle } from './LibraryPageSkin'

const LibraryPage = () => {
	localStorage.setItem('closed', false)
	return (
		<div style={mainContainerStyle} id="main-container">
			<AuthNavbar />
			<MainLibrary />
			<LoginFooter />
		</div>
	)
}

export default LibraryPage
