import { openDB } from 'idb'

export const getBookByIDB = async (id) => {
	const db = await createDatabase()
	const tx = db.transaction('pdfs', 'readonly')
	const store = tx.objectStore('pdfs')
	const data = await store.get(id)
	return data
}

export const insertData = async (data) => {
	const db = await createDatabase()
	const tx = db.transaction('pdfs', 'readwrite')
	const store = tx.objectStore('pdfs')
	await store.put(data)
	await tx.complete
}

async function createDatabase() {
	const db = await openDB('books', 1, {
		upgrade(db) {
			if (!db.objectStoreNames.contains('pdfs')) {
				const booksStore = db.createObjectStore('pdfs', { keyPath: 'id' })
				booksStore.createIndex('title', 'title', { unique: true })
			}
		}
	})
	return db
}

export const deleteBooksFromIndexedDB = async (books) => {
	const bookIds = getExpiredBooks(books)
	const db = await createDatabase()
	const transaction = db.transaction('pdfs', 'readwrite')
	const objectStore = transaction.objectStore('pdfs')
	const deleteRequests = bookIds.map((id) => objectStore.delete(id))
	return Promise.all(deleteRequests).then(() => transaction.complete)
}

export const deleteSingleBookFromIndexedDB = async (book) => {
	const db = await createDatabase()
	const transaction = db.transaction('pdfs', 'readwrite')
	const objectStore = transaction.objectStore('pdfs')
	await objectStore.delete(book)
	await transaction.complete
}

const getExpiredBooks = (books) => {
	const expirationDate = process.env.REACT_APP_EXPIRATION_DAYS
	const expiredBooks = []
	for (const { updatedAt, _id } of books) {
		const givenDate = new Date(updatedAt)
		const currentDate = new Date()
		const timeDiff = currentDate.getTime() - givenDate.getTime()
		const dayDiff = Math.floor(timeDiff / 86_400_000)

		if (dayDiff > expirationDate) {
			expiredBooks.push(_id)
		}
	}
	return expiredBooks
}
