
import { consoleLog } from "./common.util";

window.dataLayer = window.dataLayer || []


// export function gtag(...args) {
 export function gtag() {
  if (typeof window !== "undefined" && window.dataLayer) {
    // consoleLog("args =", args);
    // consoleLog("arguments =", arguments);

    // window.dataLayer.push(...args);
    window.dataLayer.push(arguments);
  } else {
    console.warn("Google Tag Manager is not initialized");
  }
}



export const sendEventToGTM = (eventData) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventData.eventName,
      eventCategory: eventData.eventCategory,
      eventAction: eventData.eventAction,
      eventLabel:eventData.eventLabel
    });
  } else {
    console.warn('GTMsendEventToGTM - event push attempted on the server side.');
  }
};

// Adjusted to not use the useLocation hook
export const SendPageViewEventToGTM = () => {
	const fullPath =
		window.location.origin + window.location.pathname + window.location.search

	window.dataLayer = window.dataLayer || []
	window.dataLayer.push({
		event: 'pageview',
		page_location: fullPath,
		page_path: window.location.pathname,
		page_title: document.title,
		page_referrer: document.referrer
	})
}
