import styled from '@emotion/styled/macro'
import { Dialog } from '@mui/material'
import { h4FontSize, h7FontSize, h8FontSize } from '../../appSkin.js'
export const AlertBoxContainer = styled(Dialog)`
	& .MuiPaper-root {
		max-width: 50%;
	}

	.alert-dialog-description {
		${h7FontSize}
	}

	.alert-dialog-title {
		${h4FontSize}
	}

	.alert-dialog-button {
		font-weight: bold;
		${h8FontSize}
	}
`
