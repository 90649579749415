import styled from '@emotion/styled/macro'
import { h8FontSize } from 'appSkin'

export const QnaDisplayContainer = styled.div`
	width: 100%;
	// height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	position: relative;

	.maximizeButton {
		${h8FontSize}
	}
`
