import { Fragment, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import NavbarHPOT from 'components/NavbarHPOT'
import PayoffTwo from 'components/PayoffTwo'
import CustomersReview from 'components/CustomersReview'
import TutorialVideo from 'components/TutorialVideo'
import LoginFooter from 'components/LoginFooter'
import backgroundImage from 'assets/Background-Image.png'

import { Container } from './homePageOpenTestingSkin'
import ModalVideo from 'react-modal-video'
import { getUserReview } from 'api/review'
import { useQuery } from 'react-query'

//Import components necessary to run geoLocalization

//Import loading indicator to prevent users from restricted countries to temporarily see the actual home page
import LoadingIndicator from '../../components/LoadingIndicator'

const HomePageOpenTesting = () => {
	const { t } = useTranslation()
	const [isOpen, setOpen] = useState(false)
	const [url, setUrl] = useState('')
	const { isLoading, data } = useQuery('review', getUserReview)
	// render your home page here

	return (
		<Fragment>
			<ModalVideo
				channel="youtube"
				width="5000"
				autoplay
				isOpen={isOpen}
				videoId={url}
				onClose={() => setOpen(false)}
			/>

			{/* Add the title and description HTML metatags */}
			<Helmet>
				<title>{t('HP_title')}</title>
				<meta name="description" content={t('HP_description')} />

				{/* Open Graph tags */}
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content={`${process.env.REACT_APP_BASE_URL}/mktg/pmuc02`}
				/>
				<meta property="og:title" content={t('HP_title')} />
				<meta property="og:description" content={t('HP_description')} />
				<meta property="og:image" content="%PUBLIC_URL%/ATutor_OG.jpg" />
				<meta property="og:image:width" content="227" />
				<meta property="og:image:height" content="458" />
			</Helmet>

			<div>
				<Container background={backgroundImage}>
					<NavbarHPOT />
				</Container>
				<PayoffTwo />

				{/* Conditional display of the title of component 'CustomersReview' */}
				{data && data.length !== 0 && (
					<Grid container>
						<Grid item xs={0.5} />
						<Grid item xs={11.5} sx={{ mt: '2%' }}>
							<Typography className="h2" fontWeight={600} variant="h4">
								{t('earlyAdopters_review')}
							</Typography>
						</Grid>
					</Grid>
				)}
				<CustomersReview isLoading={isLoading} data={data} />
				<TutorialVideo modalData={{ isOpen, setOpen, setUrl }} />
				<LoginFooter />
			</div>
		</Fragment>
	)
}

export default HomePageOpenTesting
