import { styled } from '@mui/material/styles'
import { Grid, Typography, Box } from '@mui/material'
import Paper from '@mui/material/Paper'

import book from 'assets/book.png'
import Btn from '../../images/btn.png'
import VerticalCarouselForBook from '../bookTable'
import './style.css'
import { fetchQuestionsBooks } from 'api/dashboard'
import { useQuery } from 'react-query'
import useAuth from 'hooks/useAuth'

const Library = () => {
	const Item = styled(Paper)(() => ({
		backgroundColor: 'transparent',
		padding: 0,
		border: 'none',
		boxShadow: 'none',
		textAlign: 'center',
		color: 'white'
	}))
	const { auth } = useAuth()

	const { data, isLoading, isFetching } = useQuery(
		['dashboard-user-book', auth.user.id],
		async () => {
			const response = await fetchQuestionsBooks(auth.user.id)
			// Filter the response data to only include items where uploaded is true
			console.log(
				'Library',
				response.filter((item) => item.uploaded)
			)
			return response.filter((item) => item.uploaded)
		},
		{
			refetchOnWindowFocus: false,
			retry: false,
			enabled: Boolean(auth.user.id)
		}
	)

	return (
		<Grid container>
			<Grid item xs={12}>
				<Box
					className="container-head"
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						height: '80px'
					}}
				>
					<Item sx={{ display: 'flex', alignItems: 'center', width: '200px' }}>
						<img src={book} alt="" />
						<Typography
							className="h2"
							sx={{ marginLeft: '15px', fontWeight: 600 }}
							variant="h4"
						>
							Library
						</Typography>
					</Item>
					{/* <Item>
						<button
							className="plus-btn"
							style={{
								background: 'transparent',
								border: 'none',
								outline: 'none'
							}}
						>
							<img style={{ width: '100%' }} src={Btn} alt="" />
						</button>
					</Item> */}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<VerticalCarouselForBook
					userdashboardData={data}
					isQuestionBooksLoading={isLoading || isFetching}
				/>
			</Grid>
		</Grid>
	)
}

export default Library
