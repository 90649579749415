import styled from '@emotion/styled'

export const SubscriptionSkin = styled.div`
	font-family: 'Segoe UI', sans-serif;

	.font {
		font-weight: 500;
	}

	.header-section {
		background-color: #a6b1ff;
		color: #000;
		font-weight: bold;
		text-align: center;
		border-radius: 15px 15px 0 0;
		height: 70px;
		display: flex;
		align-items: center;
	}

	.discount-section {
		text-align: center;
		height: 70px;
		background: #d9e0f2;
		color: #000;
		height: 70px;
		display: flex;
		align-items: center;
	}

	.light-bg {
		background: #d9e0f2;
		color: #000;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.costing-section {
		color: #fff;
		text-align: center;
		height: 70px;
		display: flex;
		align-items: center;
	}

	.intial-section {
		background: #d9e0f2;
		height: 50px;
		color: #000;
	}

	.extend-section {
		height: 50px;
		color: #fff;
	}

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.jutify-end {
		justify-content: flex-end;
	}

	.align-center {
		display: flex;
		align-items: center;
		padding-left: 3%;
		text-align: start;
	}

	.align-center label {
		display: flex;
		align-items: center;
	}

	.justify-center {
		display: flex;
		justify-content: center;
	}

	.subs_btn {
		height: 60px;
		padding: 0 7.8125%;
		background: #6a7ae9 !important;
		border: 2px solid #55657e !important;
		border-radius: 10px !important;
		cursor: pointer;
		color: #fff;
	}

	[type='radio']:checked,
	[type='radio']:not(:checked) {
		display: none;
	}
	[type='radio']:checked + label,
	[type='radio']:not(:checked) + label {
		cursor: pointer;
		color: #666;
	}
	[type='radio']:checked + label:before,
	[type='radio']:not(:checked) + label:before {
		content: '';
		width: 38px;
		height: 38px;
		border: 1px solid #ddd;
		border-radius: 100%;
		background: #fff;
		display: inline-block;
	}
	[type='radio']:checked + label:after,
	[type='radio']:not(:checked) + label:after {
		content: '';
		width: 38px;
		height: 38px;
		display: inline-block;
		background: #f87da9;
		position: absolute;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	[type='radio']:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	[type='radio']:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(0.7);
	}
`
