// src/store/reducers/HPOT_showCarousel.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showCarousel: false,
};

const showCarouselHPOTSlice = createSlice({
  name: 'showCarouselHPOT',
  initialState,
  reducers: {
    setShowCarousel(state, action) {
      state.showCarousel = action.payload;
    },
  },
});

// Export the action created by createSlice
export const { setShowCarousel } = showCarouselHPOTSlice.actions;

// Export the reducer to be used in the root reducer
export default showCarouselHPOTSlice.reducer;
