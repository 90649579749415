import { useEffect, useState } from 'react'
import { getCookie } from 'utils/common.util'
import { gtag } from 'utils/gtag'
import { SendPageViewEventToGTM } from '../utils/gtag'

export const useGtmPageViewTracking = (countryCode) => {
	const [consentSent, setConsentSent] = useState(false)
	const GDPR_COUNTRIES = process.env.REACT_APP_GDPR_COUNTRIES.split(', ')

	useEffect(() => {
		// Only run this part if consent has not been sent yet and country code is null or undefined.
		if (consentSent || !countryCode) return

		if (!GDPR_COUNTRIES.includes(countryCode)) {
			gtag('consent', 'default', {
				ad_storage: 'granted',
				analytics_storage: 'granted',
				personalization_storage: 'granted',
				functionality_storage: 'granted',
				security_storage: 'granted',
				ad_personalization: 'granted',
				ad_user_data: 'granted'
			})
		} else {
			const consentModeCookie = getCookie('consentMode')
			if (consentModeCookie) {
				const consentMode = JSON.parse(consentModeCookie)
				gtag('consent', 'update', consentMode)
			} else {
				// Set default consents at the user's first session
				//****************************************** */
				console.log(
					"useGtmPageViewTracking sets the default values of user's consents at the beginning of a session"
				)
				//****************************************** */

				gtag('consent', 'default', {
					ad_storage: 'denied',
					analytics_storage: 'denied',
					personalization_storage: 'denied',
					functionality_storage: 'denied',
					security_storage: 'denied',
					ad_personalization: 'denied',
					ad_user_data: 'denied'
				})
			}
		}
		//****************************************** */
		console.log('useGtmPageViewTracking launches SendPageViewEventToGTM')
		//****************************************** */
		setConsentSent(true)
		SendPageViewEventToGTM()
	}, [consentSent, countryCode]) // Dependency array updated to reflect actual dependencies

	// No return statement needed since nothing is being returned from this hook.
}
