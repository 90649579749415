/** @format */

import styled from '@emotion/styled/macro'

export const PasswordRecoverySkin = styled.div`
	.create_container {
		display: flex;
		justify-content: center;
		margin-bottom: 6%;
		margin-top: 1%;
	}
	.logo-image {
		height: auto;
		width: 50%;
	}
	.create_pass_container {
		border: 1px solid black;
		border-radius: 10px;
		padding: 5% 5% 5% 5%;
		text-align: left;
	}

	.create_pass_labels {
		display: block;
		text-align: left;
		color: gray;
	}
	.login-acc-labels {
		display: block;
		text-align: left;
		color: gray;
		margin-left: 5%;
	}
	.login-title {
		color: gray;
		text-align: center;
		margin-top: 2%;
		margin-bottom: 2%;
	}
	.input-container {
		padding-top: 2%;
		padding-bottom: 2%;
	}
	.create_pass_input {
		width: 100%;
		padding-top: 7px;
		padding-bottom: 7px;
		border: 1px solid black;
		border-radius: 5px;
	}
	.login-btn-link {
		text-decoration: none;
	}
	.login-btn {
		background: #9292ff;
		color: white;
		width: 100%;
		border-radius: 5px;
		border: 1px solid gray;
		margin-top: 3%;
		text-transform: inherit;
	}

	.login-btn:hover {
		background: #9292ff;
	}
	.policy-text {
		display: block;
		text-align: left;
		padding-top: 2%;
		padding-bottom: 2%;
		color: gray;
	}
	.create_pass_p {
		color: #707070;
		margin-top: 0;
	}
	.create_pass_h4 {
		color: #707070;
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.h6,
	.h7,
	.h4 {
		font-family: 'Arial';
	}
	.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
`
