import { useState } from 'react'
import { useQuery } from 'react-query'
import { styled } from '@mui/material/styles'
import { Grid, Typography, Box } from '@mui/material'
import Paper from '@mui/material/Paper'
import qna from 'assets/qna.png'
import VerticalCarousel from './../QnATable'
import useAuth from '../../hooks/useAuth'
import { fetchQuestionsBooks } from 'api/dashboard'
import { getQALeft } from 'api/qna'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import { QARightSide } from './qnaSkin'

const Item = styled(Paper)(() => ({
	backgroundColor: 'transparent',
	padding: 0,
	border: 'none',
	boxShadow: 'none',
	textAlign: 'center',
	color: 'white'
}))

const QnA = () => {
	const {
		auth,
		auth: { token }
	} = useAuth()
	const [selectText, setSelectText] = useState('all')
	const newQnaList = []
	const {
		data,
		isLoading,
		isFetching,
		refetch: refetchBookList
	} = useQuery(
		['qna-list-book', auth.user.id],
		async () => {
			const response = await fetchQuestionsBooks(auth.user.id)
			//console.log('QnA fetchQuestionsBooks:', response) // Log the response from the API
			return response
		},
		{
			refetchOnWindowFocus: false,
			retry: false,
			enabled: Boolean(auth.user.id)
		}
	)

	const {
		data: qnaList,
		isLoading: isQnaListLoading,
		isFetching: isQnaListFetching,
		refetch
	} = useQuery(
		['qna-list', token],
		async () => {
			const response = await getQALeft(token)
			return response
		},
		{
			refetchOnWindowFocus: false,
			retry: false,
			enabled: Boolean(token)
		}
	)

	//console.log('getQALeft: qnaList =', qnaList)//log the outcome of query getQALeft

	if (
		qnaList &&
		qnaList &&
		qnaList.QAExpBookTitle.length > 0 &&
		data &&
		data.length > 0
	) {
		data.forEach((x, xi) => {
			qnaList.QAExpBookTitle.forEach((y, yi) => {
				if (x.title === y) {
					newQnaList.push({
						title: x.title,
						id: qnaList.QAExpBookId[yi],
						low: x.low,
						medium: x.medium,
						urgent: x.urgent,
						T: x.T
					})
				}
			})
		})
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<Box
					className="container-head"
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						height: '80px'
					}}
				>
					<Item sx={{ display: 'flex', alignItems: 'center', width: '200px' }}>
						<img src={qna} alt="" />
						<Typography
							className="h2"
							sx={{ marginLeft: '15px', fontWeight: 600 }}
							variant="h4"
						>
							Q&A
						</Typography>
					</Item>

					<Item>
						<QARightSide>
							<span
								onClick={() => {
									refetch()
									refetchBookList()
								}}
							>
								<RefreshRoundedIcon />
							</span>
							<select
								onChange={(ev) => setSelectText(ev.target.value)}
								defaultValue={selectText}
								className="h7"
								style={{
									width: '100%',
									background: '#4C545A',
									border: 'none',
									outline: 'none',
									color: 'white',
									borderRadius: '3px',
									display: 'block',
									padding: '15px'
								}}
							>
								<option value="all" className="h7">
									All overdue
								</option>
								<option value="!!!" className="h7">
									Urgent
								</option>
							</select>
						</QARightSide>
					</Item>
				</Box>
			</Grid>

			<Grid item xs={12}>
				<VerticalCarousel
					selectText={selectText}
					questions={newQnaList}
					loading={
						isLoading || isQnaListLoading || isQnaListFetching || isFetching
					}
				/>
			</Grid>
		</Grid>
	)
}

export default QnA
