import { useState, useEffect, Fragment } from 'react'
import { Button, Grid, Typography, Rating } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { reviewStyles } from './ReviewStyles'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import useAuth from 'hooks/useAuth'
import {
	getReviewByUserId,
	createReview,
	editReviewByUserId,
	deleteReview
} from '../../api/review'
import { useQuery } from 'react-query'
import { getUserProfile } from 'api/user'
import { Link } from 'react-router-dom'
import AlertBoxHTML from 'components/AlertBoxHTML'
import LoadingIndicator from 'components/LoadingIndicator'

const ReviewComponent = ({ userBirthDate }) => {
	const { t } = useTranslation()
	const classes = reviewStyles()
	const [state, setState] = useState({
		title: '',
		description: ''
	})

	const [rating, setRating] = useState(0)
	const { auth } = useAuth()
	const [response, setResponse] = useState()
	const [isEdit, setIsEdit] = useState(false)
	const [confirm, setConfirm] = useState(false)
	const [loading, setLoading] = useState(false)
	const token = sessionStorage.getItem('token')
	const [validationMsg, setValidationMsg] = useState('')
	const { data, refetch } = useQuery('review', () =>
		getReviewByUserId(auth.user.id)
	)

	const [userBirth, setUserBirth] = useState(null)
	const getUser = async () => {
		const data = await getUserProfile(auth.user.id)
		setUserBirth(data.data.user_birthday)
	}

	useEffect(() => {
		getUser()
	}, [auth, userBirth])

	useEffect(() => {
		if (data) {
			setState({
				title: data.userRev_title,
				description: data.userRev_content
			})
			setRating(data.userRev_score)
		}
		if (response) {
			refetch()
		}
	}, [data, response, refetch])

	const handleChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value })
	}

	const handleRatingChange = (e, value) => {
		setRating(value)
	}

	const handleOnSave = async (e) => {
		if (!userBirth) {
			setValidationMsg('not_birthdate_msg')
			return
		}
		if (!rating) {
			setValidationMsg('rating_validation_msg')
			return
		}
		if (!state.title) {
			setValidationMsg('review_title_validation_msg')
			return
		}

		if (!isEdit) {
			await setValidationMsg('')
			const payload = {
				userRev_title: state.title,
				userRev_content: state.description,
				userRev_score: rating
			}
			setLoading(true)
			const response = await createReview({
				token: token,
				id: auth.user.id,
				review: payload
			})
			if ([200, '200'].includes(response.status)) {
				await setResponse(response)
				setIsEdit(false)
				setLoading(false)
				refetch()
			}
		} else {
			const payload = {
				userRev_title: state.title,
				userRev_content: state.description,
				userRev_score: rating
			}
			setValidationMsg('')
			setLoading(true)
			const response = await editReviewByUserId({
				token: token,
				id: auth.user.id,
				review: payload
			})
			if ([200, '200'].includes(response.status)) {
				await setResponse(response)
				await setIsEdit(false)
				setLoading(false)
				refetch()
			}
		}
		refetch()
		setIsEdit(false)
	}

	const handleOnCancel = () => {
		setIsEdit(false)
		refetch()
		setValidationMsg('')
		setState({
			title: data.userRev_title,
			description: data.userRev_content
		})
		setRating(data.userRev_score)
	}

	const handleEdit = () => {
		setIsEdit(true)
	}

	const handleDelete = async () => {
		const response = await deleteReview({
			token: auth.token
		})
		if (response.status === 200) {
			setState({ title: '', description: '' })
			setRating(0)
			setConfirm(false)
			refetch()
		}
	}

	return loading ? (
		<LoadingIndicator />
	) : (
		<>
			<Grid container="fluid" className={classes.modalBar}>
				<Grid className="personalData">
					<Grid xs={12} className={classes.gridPadding}>
						<Typography className={`h2 ${classes.text_color}`}>
							{t('review_title')}
						</Typography>
					</Grid>
					<Grid xs={12} className={classes.gridPadding}>
						<Typography className={`h4 ${classes.text_color}`}>
							{t('score_title')}
						</Typography>
						<Rating
							defaultValue={0}
							size="large"
							className={`h3 ${classes.starIconColor}`}
							precision={0.5}
							readOnly={!(isEdit || userBirth) || (state.title && !isEdit)} // this makes the rating readonly unless editing is active
							emptyIcon={
								<StarBorderIcon
									fontSize="inherit"
									className={classes.emptyStarBordarColor}
								/>
							}
							name="rating"
							value={rating}
							onChange={(e, v) => handleRatingChange(e, v)}
						/>
					</Grid>
					<Grid xs={12} className={classes.gridPadding}>
						<Typography
							className={`h4 ${classes.text_color} ${classes.container}`}
						>
							{t('title')}
						</Typography>
						<input
							className={`h5 ${classes.titleInput}`}
							name="title"
							id="title"
							value={state.title}
							onChange={(e) => handleChange(e)}
							disabled={
								(!isEdit && data && data.userRev_title) || !userBirthDate
							}
							placeholder={t('review_title_length_validation_msg')}
						/>
					</Grid>
					<Grid
						xs={12}
						className={` ${classes.gridPadding} ${classes.container}`}
					>
						<Typography className={`h4 ${classes.text_color}`}>
							{t('description')}
						</Typography>
						<textarea
							name="description"
							value={state.description}
							onChange={(e) => handleChange(e)}
							className={`h5 ${classes.description}`}
							disabled={
								(!isEdit && data && data.userRev_content) || !userBirthDate
							}
						/>
					</Grid>
					<Grid xs={1}>
						<Typography className={`h4 ${classes.msgColor}`}>
							{t(validationMsg)}
						</Typography>
					</Grid>
					{!userBirthDate && (
						<Grid xs={12}>
							<Typography className={`h4 ${classes.warningText}`}>
								{t('review_warning_text_1')}
								<Link to="/profile" className={classes.warningLink}>
									{t('personal_data_review_warning')}
								</Link>{' '}
								{t('review_warning_text_2')}
							</Typography>
						</Grid>
					)}

					<Grid
						xs={12}
						className={` ${classes.btnGridPadding} ${classes.container}`}
					>
						{(!data || isEdit) && userBirthDate ? (
							<>
								<Button
									className={`h7 ${classes.btn}`}
									onClick={(e) => handleOnSave(e)}
								>
									{t('save')}
								</Button>
								<Button
									onClick={handleOnCancel}
									className={`h7 ${classes.btn} ${classes.btnMargin}`}
								>
									{t('cancel')}
								</Button>
							</>
						) : null}
						{!isEdit && data && userBirthDate && (
							<>
								<Button onClick={handleEdit} className={`h6 ${classes.btn}`}>
									<EditIcon
										onClick={handleEdit}
										className="h4"
										style={{ padding: '1.06% 4%' }}
									/>
								</Button>
								<Button
									onClick={() => setConfirm(true)}
									className={`h6 ${classes.btn}`}
									style={{ marginLeft: 15 }}
								>
									<DeleteIcon
										onClick={() => setConfirm(true)}
										className="h4"
										style={{ padding: '1.06% 4%' }}
									/>
								</Button>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>

			<AlertBoxHTML
				open={confirm}
				setOpenAlertBox={handleDelete}
				setCancelAlertBox={setConfirm}
				btnText="Confirm"
				cancel={true}
				description={
					<Trans i18nKey="delete_review_confirmation">
						<p></p>
					</Trans>
				}
			/>
		</>
	)
}

export default ReviewComponent
