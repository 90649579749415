import styled from '@emotion/styled/macro'
// import { h1FontSize } from '../../appSkin.js'

export const Container = styled.div`
	padding: 0 20px;
	@media (min-width: 1024px) {
		p {
			font-size: 16pt !important;
		}
		h1 {
			font-size: 20pt !important;
		}
		h2 {
			font-size: 18pt !important;
		}
	}

	a {
		margin: 0 4px;
		cursor: pointer;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	p.MsoNormal,
	li.MsoNormal,
	div.MsoNormal {
		margin-top: 0cm;
		margin-right: 0cm;
		margin-bottom: 8pt;
		margin-left: 0cm;
		line-height: 107%;
		font-size: 11pt;
		font-family: 'Calibri', sans-serif;
	}
	h1 {
		mso-style-link: 'Titolo 1 Carattere';
		margin-top: 12pt;
		margin-right: 0cm;
		margin-bottom: 0cm;
		margin-left: 0cm;
		line-height: 107%;
		page-break-after: avoid;
		font-size: 16pt;
		font-family: 'Calibri Light', sans-serif;
		color: #2f5496;
		font-weight: normal;
	}
	h2 {
		mso-style-link: 'Titolo 2 Carattere';
		margin-top: 2pt;
		margin-right: 0cm;
		margin-bottom: 0cm;
		margin-left: 0cm;
		line-height: 107%;
		page-break-after: avoid;
		font-size: 13pt;
		font-family: 'Calibri Light', sans-serif;
		color: #2f5496;
		font-weight: normal;
	}
	a:link,
	span.MsoHyperlink {
		color: #0563c1;
		text-decoration: underline;
	}
	p.MsoListParagraph,
	li.MsoListParagraph,
	div.MsoListParagraph {
		margin-top: 0cm;
		margin-right: 0cm;
		margin-bottom: 8pt;
		margin-left: 36pt;
		line-height: 107%;
		font-size: 11pt;
		font-family: 'Calibri', sans-serif;
	}
	p.MsoListParagraphCxSpFirst,
	li.MsoListParagraphCxSpFirst,
	div.MsoListParagraphCxSpFirst {
		margin-top: 0cm;
		margin-right: 0cm;
		margin-bottom: 0cm;
		margin-left: 36pt;
		line-height: 107%;
		font-size: 11pt;
		font-family: 'Calibri', sans-serif;
	}
	p.MsoListParagraphCxSpMiddle,
	li.MsoListParagraphCxSpMiddle,
	div.MsoListParagraphCxSpMiddle {
		margin-top: 0cm;
		margin-right: 0cm;
		margin-bottom: 0cm;
		margin-left: 36pt;
		line-height: 107%;
		font-size: 11pt;
		font-family: 'Calibri', sans-serif;
	}
	p.MsoListParagraphCxSpLast,
	li.MsoListParagraphCxSpLast,
	div.MsoListParagraphCxSpLast {
		margin-top: 0cm;
		margin-right: 0cm;
		margin-bottom: 8pt;
		margin-left: 36pt;
		line-height: 107%;
		font-size: 11pt;
		font-family: 'Calibri', sans-serif;
	}
	span.Titolo1Carattere {
		mso-style-name: 'Titolo 1 Carattere';
		mso-style-link: 'Titolo 1';
		font-family: 'Calibri Light', sans-serif;
		color: #2f5496;
	}
	span.Titolo2Carattere {
		mso-style-name: 'Titolo 2 Carattere';
		mso-style-link: 'Titolo 2';
		font-family: 'Calibri Light', sans-serif;
		color: #2f5496;
	}
	.MsoPapDefault {
		margin-bottom: 8pt;
		line-height: 107%;
	}
	@page WordSection1 {
		size: 595.3pt 841.9pt;
		margin: 70.85pt 2cm 2cm 2cm;
	}
	div.WordSection1 {
		page: WordSection1;
	}
	/* List Definitions */
	ol {
		margin-bottom: 0cm;
	}
	ul {
		margin-bottom: 0cm;
	}
`
