import styled from '@emotion/styled/macro'

export const CreateAccountCon = styled.div`
	.signup-container {
		display: flex;
		justify-content: center;
		margin-bottom: 2%;
		margin-top: 1%;
	}
	.logo-image {
		height: auto;
		width: 50%;
	}
	.signup-input-container {
		border: 1px solid black;
		border-radius: 10px;
		padding: 5% 5% 5% 5%;
		
	}
	.signup-labels {
		display: block;
		text-align: left;
		color: gray;
	}

	.signup-title {
		color: gray;
		text-align: center;
		margin-top: 2%;
		margin-bottom: 2%;
	}
	.input-container {
		padding-top: 2%;
		padding-bottom: 2%;
	}
	.signup-input {
		width: 100%;
		padding-top: 7px;
		padding-bottom: 7px;
		border: 1px solid black;
		border-radius: 5px;
		height: auto !important;
	}
	.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
		height: auto !important;
		padding: 0;
		font-family: Arial;
	}

	input {
		padding-left: 5px !important;
	}
	.signup-btn {
		background: #9292ff;
		color: white;
		width: 100%;
		border-radius: 5px;
		border: 1px solid gray;
		margin-top: 3%;
		text-transform: inherit;
	}

	.signup-btn:hover {
		background: #9292ff;
	}
	.policy-text {
		display: block;
		text-align: left;
		padding-top: 2%;
		padding-bottom: 2%;
		color: gray;
	}

	div.Mui-focused {
		border-color: black;
	}
	.h4,
	.h6,
	.h7 {
		font-family: Segoe UI;
	}
	.css-1uvydh2 {
		padding: 0 !important;
	}
	.gEPEJ .signup-input {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	#outlined-adornment-password {
		padding-left: 2% !important;
	}
	input {
		padding-left: 2% !important;
	}
`
