import React, { useState, useEffect, useCallback } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { ErrorMessage } from './PDFRendererSkin'
import { useTranslation } from 'react-i18next'
import './style.css'

// const calculatedScale = 0.00416667 * window.innerWidth - 4
// const scaleRendering = Math.max(1, Math.min(calculatedScale, 2.4))

const PDFRenderer = ({ file, modalWidth }) => {
	//Check the nature of file
	// console.log('This is the URL passed to PDFRenderer =>', file)

	const [numPages, setNumPages] = useState(null)
	const { t } = useTranslation()
	const [scaleRendering, setScaleRendering] = useState(1)

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages)
	}

	// function to capture the original width of a page of the rendered PDF
	const onPageLoadSuccess = useCallback(
		({ width }) => {
			console.log('\nWidth of the original PDF (first page):', width)
			// Calculate the scale here based on modalWidth and the width of the original PDF
			let automaticScale
			if (width && modalWidth) {
				const scaleCoefficient = 0.95
				automaticScale = (modalWidth * scaleCoefficient) / width
				setScaleRendering(automaticScale)
			} else {
				automaticScale = 1
				setScaleRendering(automaticScale)
			}
		},
		[modalWidth, setScaleRendering]
	)

	useEffect(() => {
		// console.log('Original PDF Width:', width);
		console.log('scale =>', scaleRendering)
		console.log('modalWidth =>', modalWidth)
	}, [scaleRendering, modalWidth])

	const handleContentClick = (event) => {
		// Prevent the click event from propagating to the parent elements
		event.stopPropagation();
	  };
	return (
		<Document
			file={file}
			onLoadSuccess={onDocumentLoadSuccess}
			loading={false}
			error={<ErrorMessage>{t('PDFLoadingError')}</ErrorMessage>}
		>
			{Array.from(new Array(numPages), (el, index) => (
				 <div onClick={handleContentClick} key={`page_${index + 1}`}>
				<Page
					key={`page_${index + 1}`}
					pageNumber={index + 1}
					// Compute scale only for the first page
					onLoadSuccess={index === 0 ? onPageLoadSuccess : undefined}
					scale={scaleRendering}
					renderAnnotationLayer={true}
					renderTextLayer={false}
				/>
				</div>
			))}
		</Document>
	)
}

// scale={window.innerWidth > 1200 ? 1.3 : 1}

export default PDFRenderer
