import styled from '@emotion/styled/macro'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { h9FontSize } from '../../appSkin'

export const StyledDeleteIcon = styled(DeleteIcon)`
	${h9FontSize};
	color: ghostwhite;
	cursor: pointer;
`

export const StyledVisibilityIcon = styled(VisibilityIcon)`
	${h9FontSize};
	color: ghostwhite;
	cursor: pointer;
`

export const StyledEditIcon = styled(EditIcon)`
	${h9FontSize};
	color: ghostwhite;
	cursor: pointer;
`
export const CommentHolderContainer = styled.div`
	.comment {
		justify-content: space-between;
	}
	.comment-text {
		color: white;
		white-space: normal;
	}

	.comment-editor {
		justify-content: flex-start;
	}
`
