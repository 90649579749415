import { makeStyles } from '@mui/styles'

export const reviewStyles = makeStyles({
	modalBar: {
		justifyContent: 'center !important'
	},
	modalContainer: {
		// rowGap: "10px",
		marginTop: '0 !important'
	},
	modalWeb: {
		position: 'absolute',
		top: '20%',
		left: '20%',
		right: '20%',
		width: '60%',
		// margin:'auto',
		background: '#2A333A',
		border: '1px solid #55657f',
		borderRadius: '5px',
		paddingTop: '1.58%'
	},
	modalPhone: {
		position: 'absolute',
		top: '20%',
		left: '5%',
		right: '5%',
		width: '90%',
		// margin:'auto',
		background: '#2A333A',
		padding: '0% 1% 0%',
		border: '1px solid #55657f',
		borderRadius: '5px',
		paddingTop: '1.58%'
	},
	inputStep: {
		width: '100%',
		// marginBottom: 17,
		background: 'transparent',
		border: '1px solid white',
		borderRadius: 5,
		color: '#ffffff',
		height: '100%',
		fontFamily: 'Segoe UI',
		'&:active': {
			outline: 'none'
		}
	},

	text_color: {
		color: 'white'
	},

	starIconColor: {
		color: '#FFA41C'
	},

	emptyStarBordarColor: {
		color: 'white'
	},

	btn: {
		background: '#6a7ae9 !important',
		border: '2px solid #55657e !important',
		borderRadius: '10px !important',
		textTransform: 'capitalize !important',
		color: 'white',
		width: '8%'
	},

	btnMargin: {
		marginLeft: '60px'
	},

	container: {
		// marginTop: "2%"
	},

	description: {
		background: 'white',
		borderRadius: '10px',
		height: '220px',
		width: '100%',
		padding: '2.14%',
		fontFamily: 'Segoe UI'
	},

	titleInput: {
		background: 'white',
		borderRadius: '10px',
		height: '57px',
		width: '100%',
		padding: '2.14%',
		fontFamily: 'Segoe UI'
	},
	input: {
		width: '100%',
		textAlign: 'left',
		// marginBottom: 17,
		background: 'transparent',
		border: '1px solid white',
		borderRadius: 10,
		color: '#ffffff',
		height: '100%',
		fontFamily: 'Segoe UI',
		'&:active': {
			outline: 'none'
		}
	},
	msgColor: {
		color: 'white',
		whiteSpace: 'nowrap'
	},

	gridPadding: {
		paddingTop: '2.15%'
	},

	btnGridPadding: {
		paddingTop: '4.3%'
	},
	warningText: {
		color: '#E9BB6A'
	},
	warningLink: {
		color: '#fff',
		cursor: 'pointer'
	}
})
