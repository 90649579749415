import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { Box, Button, Grid, Typography } from '@mui/material'
import Carousel from 'react-multi-carousel'
import { useQuery } from 'react-query'
import { getPrivateTuts } from '../../api/tutorial'
import LoadingIndicator from './../LoadingIndicator'
import { TutorialVideoCon } from './skins'
import TutorialLogo from 'assets/video.png'
import { getVideoIDByUrl } from 'utils/getYoutubeId'

export function TutorialCarousel(props) {
	const { videos } = props
	const { responsive } = props
	const { modalProps } = props
	const { isOpen, setOpen, setUrl } = modalProps.modalData

	const videosItems = videos.map((video, i) => {
		return (
			<div key={`videos-${i}`} className="videos-item">
				<Grid container className="containerSize">
					<Grid item xs={12}>
						<img
							src={`./assets/thumbnail/thumbnail-${video.tut_displayOrder}.png`}
							className="videos-image"
							alt=""
						/>
					</Grid>
					<Grid
						sx={{ padding: '0 15px', bgcolor: 'white', height: '123px' }}
						item
						xs={12}
					>
						<Typography
							// variant="h5"
							className="videos-name tutorials-video-title h7"
						>
							{video.tut_title}
						</Typography>
					</Grid>
					<Grid sx={{ padding: '0 15px', bgcolor: 'white' }} item xs={6}>
						<Typography
							variant="body2"
							className="tutorial-videos-text tutorials-video-duration h8"
						>
							{video.tut_duration}
						</Typography>
					</Grid>
					<Grid sx={{ padding: '0 5px', bgcolor: 'white' }} item xs={6}>
						<Button
							onClick={() => {
								const url = getVideoIDByUrl(video.tut_videoURL)
								setUrl(url)
								setOpen(!isOpen)
							}}
							variant="contained"
							className="start-button tutorials-video-button h11"
						>
							Start Now
						</Button>
					</Grid>
				</Grid>
			</div>
		)
	})

	return (
		<Carousel
			swipeable={false}
			draggable={false}
			responsive={responsive}
			ssr // means to render carousel on server-side.
			infinite
			autoPlaySpeed={3000}
			keyBoardControl
			transitionDuration={500}
			renderButtonGroupOutside
		>
			{videosItems}
		</Carousel>
	)
}

const TutorialVideo = (props) => {
	const { isLoading, data } = useQuery('private-tutorail', getPrivateTuts, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		retry: false
	})

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 5
		},
		tablet: {
			breakpoint: { max: 1024, min: 750 },
			items: 4
		},
		mobile: {
			breakpoint: { max: 750, min: 465 },
			items: 3
		},
		smallMobile: {
			breakpoint: { max: 465, min: 0 },
			items: 1
		}
	}

	const Item = styled(Paper)(() => ({
		backgroundColor: 'transparent',
		padding: 0,
		border: 'none',
		boxShadow: 'none',
		textAlign: 'center',
		color: 'white'
	}))

	return (
		<TutorialVideoCon>
			<Grid container>
				<Grid xs={12} item>
					<Box className="container-head">
						<Item sx={{ display: 'flex', alignItems: 'center' }}>
							<img
								className="video-icon"
								src={TutorialLogo}
								alt="tutorial logo"
							/>
							<Typography
								className="h2"
								sx={{ marginLeft: '15px', fontWeight: 600 }}
								variant="h4"
							>
								Tutorials and Videos
							</Typography>
						</Item>
					</Box>
				</Grid>

				<Grid xs={12} item>
					{isLoading ? (
						<LoadingIndicator />
					) : (
						<TutorialCarousel
							modalProps={props}
							style={{ width: '80%' }}
							videos={data}
							responsive={responsive}
						/>
					)}
				</Grid>
			</Grid>
		</TutorialVideoCon>
	)
}

export default TutorialVideo
