import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}note`
})

const createConfig = () => {
	const token = sessionStorage.getItem('token')

	return {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	}
}

export const createNote = async ({ note }) => {
	const config = createConfig()
	const response = await api.post('/create', note, config)
	return response
}

export const deleteLQARevNote = async (LQARevNoteId) => {
	const config = createConfig()
	const response = await api.delete(`/delete/${LQARevNoteId}`, config)
	return response
}

export const updateLQARevNote = async (LQARevNoteId, data) => {
	const config = createConfig()
	const response = await api.put(`/edit/${LQARevNoteId}`, data, config)
	return response
}

export const getBookNoteByPageNumber = async (bookId, page) => {
	if (!page) {
		return
	}
	const config = createConfig()
	const result = await api.get(`/retrieve/${bookId}/${page}`, config)
	return result.data
}
