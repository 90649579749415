import React from 'react'
import Swal from 'sweetalert2'
import { deleteLQARevNote, updateLQARevNote } from 'api/note'
import { useTranslation } from 'react-i18next'

const AlertDialog = ({
	setConfirmDelete,
	setIsdelete,
	setIsEdit,
	step,
	setError,
	title,
	isDelete,
	isUpdate,
	data,
	Id,
	handleCancel,
	successTitle
}) => {
	const { t } = useTranslation()

	const alert = () => {
		Swal.fire({
			title: title,
			showConfirmButton: true,
			confirmButtonText: 'Yes',
			showLoaderOnConfirm: true,
			showCancelButton: true,
			preConfirm: async () => {
				if (step > 3 || step === 0) {
					Swal.showValidationMessage(t(`step_validation_msg`))
					return
				} else {
					if (isDelete) {
						return await deleteLQARevNote(Id)
							.then(async (response) => {
								if (response.error) {
									throw new Error(response.error.response.data.message)
								}
								setIsdelete(false)
								setConfirmDelete(true)
								return await response.data.message
							})
							.catch((error) => {
								setIsdelete(false)
								Swal.showValidationMessage(t(`Request failed: ${error}`))
							})
					}
					if (isUpdate) {
						return await updateLQARevNote(Id, data)
							.then((response) => {
								if (response.error) {
									throw new Error(response.error.response.data.message)
								}
								setIsEdit(false)
								return response.data.message
							})
							.catch((error) => {
								Swal.showValidationMessage(t(`Request failed: ${error}`))
							})
					}
				}
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then(async (btn) => {
			if (btn.isConfirmed) {
				setError('')
				Swal.fire({
					con: 'success',
					title: t(successTitle)
				})
				setConfirmDelete(false)
				handleCancel()
			} else {
				setIsdelete(false)
			}
		})
	}
	return <>{alert()}</>
}

export default AlertDialog
