import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}qna`
})

export const getQALeft = async (token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}
	const result = await api.get(`/list`, config)
	return result.data
}

export const getPopulateQnApopup = async (id, token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}
	const result = await api.get(`/populate/${id}`, config)
	return result.data
}

export const getCloseReviewCreateNewReview = async (
	{ LQARevId, LQARev_Qgrade },
	token
) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}
	const result = await api.get(
		`/QnAReviews/${LQARevId}/${LQARev_Qgrade}`,
		config
	)

	return result.status
}

export const getSimulateIntervals = async ({
	QARev_repN_prev,
	QARev_easyFactor_prev,
	QARev_interval_prev
}) => {
	const result = await api.get(
		`/answer/${QARev_repN_prev}/${QARev_easyFactor_prev}/${QARev_interval_prev}`
	)
	return result.data
}

export const getReviewList = async (bookId, token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	const result = await api.get(`/review/${bookId}`, config)

	return result.data
}
