import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Typography } from '@mui/material'
import { Fragment, useRef } from 'react'
import LoadingIndicator from '../LoadingIndicator'
import './style.css'
import { useStyles } from './style.js'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const VerticalCarousel = ({ selectText, loading, questions }) => {
	const { t } = useTranslation()
	// const theme = useTheme();
	// const matches = useMediaQuery(theme.breakpoints.up("sm"));
	const bodyRef = useRef()

	function scrollHandler(role) {
		const tBody = bodyRef.current
		if (role === 'pre') {
			tBody.scrollTop -= 48
		} else {
			tBody.scrollTop += 48
		}
	}

	const classes = useStyles()
	return (
		<div className="container qnaTable">
			<table>
				<thead>
					<tr>
						<td className={`h4 title ${classes.noWrap}`}>{t('book_title')}</td>
						<td>
							<Button onClick={() => scrollHandler('pre')}>
								<ExpandLessIcon />
							</Button>
						</td>
						<td className="titleOpt">
							<span className="h7">N</span>
							<span className="h7">T</span>
							<span className="h7">!!!</span>
						</td>
					</tr>
				</thead>
				<tbody ref={bodyRef}>
					{loading ? (
						<tr className="loader-tr">
							<td colSpan={3}>
								<LoadingIndicator
									display="block"
									width="100%"
									marginTop="10px"
								/>
							</td>
						</tr>
					) : questions && questions.length > 0 ? (
						questions
							// ?.sort((a, b) => {
							//   const aTitle = a.title.toLowerCase()
							//   const bTitle = b.title.toLowerCase()
							//   if (aTitle > bTitle) {
							//     return 1
							//   } else if (aTitle < bTitle) {
							//     return -1
							//   } else {
							//     return 0
							//   }
							// })
							?.map((question, index) => (
								<Fragment key={index}>
									{(question.low > 0 && selectText === '!') ||
									(question.medium > 0 && selectText === '!!') ||
									(question.urgent > 0 && selectText === '!!!') ||
									(question.medium + question.low + question.urgent > 0 &&
										(selectText === '' || selectText === 'all')) ? (
										<>
											<tr key={question._id}>
												<td
													className="h7"
													colSpan={2}
													style={{ cursor: 'pointer' }}
												>
													<Link
														to={`/qna/${question.id}`}
														style={{ lineHeight: 1.321 }}
													>
														{question.title}
													</Link>
												</td>
												<td className="titleOpt">
													{selectText === 'all' ? (
														<span className="h7">
															{question.medium + question.low + question.urgent}
														</span>
													) : null}
													{selectText === '' ? (
														<span className="h7">
															{question.medium + question.low + question.urgent}
														</span>
													) : null}
													{selectText === '!' ? (
														<span className="h7">{question.low}</span>
													) : null}
													{selectText === '!!' ? (
														<span className="h7">{question.medium}</span>
													) : null}
													{selectText === '!!!' ? (
														<span className="h7">{question.urgent}</span>
													) : null}

													<span className="h7">
														{selectText === '!' ? (
															<span className="h7">
																{question.low * question.T}
															</span>
														) : null}
														{selectText === '!!' ? (
															<span className="h7">
																{question.medium * question.T}
															</span>
														) : null}
														{selectText === '!!!' ? (
															<span className="h7">
																{question.urgent * question.T}
															</span>
														) : null}
														{selectText === '' || selectText === 'all' ? (
															<span className="h7">
																{(question.medium +
																	question.low +
																	question.urgent) *
																	question.T}
															</span>
														) : null}
													</span>

													<span className="h7">
														{' '}
														{selectText === '!!' ? <>!! </> : null}
														{selectText === '!' ? <>! </> : null}
														{selectText === '!!!' ? <> !!! </> : null}{' '}
													</span>
												</td>
											</tr>
										</>
									) : null}
								</Fragment>
							))
					) : (
						<tr className="loader-tr empty-message">
							<td>
								<Typography className="h4"> {t('empty_qna_title')}</Typography>
							</td>
						</tr>
					)}
				</tbody>
				<tfoot>
					<tr>
						<td></td>
						<td>
							<Button onClick={() => scrollHandler('next')}>
								<ExpandMoreIcon />
							</Button>
						</td>
						<td></td>
					</tr>
				</tfoot>
			</table>
		</div>
	)
}

export default VerticalCarousel
