import { useTranslation, Trans } from 'react-i18next'
import { TerminateSkin } from './TerminateAccountSkin'
import LoadingIndicator from '../LoadingIndicator'
import { Button, Grid } from '@mui/material'
import { terminate } from 'api/auth'
import AlertBoxHTML from 'components/AlertBoxHTML'
import { useState } from 'react'
import useAuth from 'hooks/useAuth'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const TerminateAccount = () => {
	const { ready, t } = useTranslation()
	const history = useHistory()
	const { auth, setAuth } = useAuth()
	const [confirm, setConfirm] = useState(false)
	const [successTermination, setSuccessTermination] = useState(false)
	const [policy, setPolicy] = useState(false)

	const terminateAccount = async () => {
		const token = sessionStorage.getItem('token')
		await terminate({ token: token, policyChange: policy.toString() })
			.then((resp) => {
				if (resp.status === 200) {
					setConfirm(false)
					setPolicy(false)
					setSuccessTermination(true)
					setTimeout(() => {
						setAuth({})
						sessionStorage.removeItem('token')
						sessionStorage.removeItem('user')
						localStorage.removeItem('timer')
						history.push('/')
					}, 5000)
				}
			})
			.catch((err) => {})
	}

	// If translations aren't ready, display the LoadingIndicator
	if (!ready) {
		return <LoadingIndicator />
	}

	return (
		<TerminateSkin>
			<div className="h7">
				{
					<Trans i18nKey="terminate_disclaimer">
						<p></p> {/* Corresponds to <0>...</0> */}
					</Trans>
				}
			</div>
			<Grid
				container
				spacing={3}
				justifyContent="center"
				style={{ marginTop: '120px' }}
			>
				<Grid item>
					<Button
						variant="contained"
						className="inviteBtn"
						style={{ marginTop: '30px' }}
						onClick={() => {
							setConfirm(true)
							setPolicy(true)
						}}
					>
						<div>
							<span className="h7" style={{ display: 'block' }}>
								<strong>Terminate Account</strong>
							</span>
							<span className="h9">due to Policy Change</span>
						</div>
					</Button>
				</Grid>
				<Grid item style={{ width: '120px' }}></Grid>
				<Grid item>
					<Button
						variant="contained"
						className="inviteBtn"
						style={{ marginTop: '30px' }}
						onClick={() => {
							setConfirm(true)
							setPolicy(false)
						}}
					>
						<span className="h7">
							<strong>Terminate Account</strong>
						</span>
					</Button>
				</Grid>
			</Grid>

			<AlertBoxHTML
				open={confirm}
				setOpenAlertBox={terminateAccount}
				setCancelAlertBox={setConfirm}
				btnText="Confirm"
				cancel={true}
				description={
					policy ? (
						<Trans
							i18nKey="terminate_warningPolicyChange"
							components={{ 0: <p />, 1: <ul />, 2: <li />, 3: <p /> }}
						/>
					) : (
						<Trans i18nKey="terminate_warning">
							<p></p>
						</Trans>
					)
				}
			/>

			<AlertBoxHTML
				open={successTermination}
				setOpenAlertBox={setSuccessTermination}
				description={
					<Trans i18nKey="terminate_confirmation">
						<p></p>
					</Trans>
				}
			/>
		</TerminateSkin>
	)
}

export default TerminateAccount
