import { useContext, useEffect, useState } from 'react'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'

import AuthContext from 'contexts/AuthProvider'
import LoadingIndicator from 'components/LoadingIndicator'
import useGeoLocation from 'hooks/useGeoLocation'
import ConsentModal from 'components/ConsentModal'
import { useGtmPageViewTracking } from 'hooks/useGTMPageViewTracking'

export const PrivateRoute = ({ component: Component, path, ...restProps }) => {
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const { countryCode } = useGeoLocation()
	useGtmPageViewTracking(countryCode)

	useEffect(() => {
		if (sessionStorage.getItem('token') && sessionStorage.getItem('user')) {
			const user = sessionStorage.getItem('user')
			setAuth({
				token: sessionStorage.getItem('token'),
				user: JSON.parse(user)
			})
		}

		setLoading(false)
	}, [setAuth])

	if (loading) {
		return <LoadingIndicator />
	}

	return (
		<Route
			path={path}
			render={({ location }) =>
				auth.token ? (
					<Component {...restProps} />
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: location }
						}}
					/>
				)
			}
		/>
	)
}

export const PublicHomePageRoute = ({
	component: Component,
	path,
	...restProps
}) => {
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const { isAllowedCountry, countryCode, isLoading } = useGeoLocation()
	useGtmPageViewTracking(countryCode)
	const GDPR_COUNTRIES = process.env.REACT_APP_GDPR_COUNTRIES.split(', ')
	const history = useHistory()

	const location = useLocation() // get the current location
	const query = location.search // extract the query string (including '?')

	useEffect(() => {
		if (isLoading) {
			return
		}

		const redirectTo = (url) => {
			history.push(url + query) // append query parameters while redirecting
		}

		if (!isAllowedCountry) {
			redirectTo('/mktg/pmuc01')
			setLoading(false)
			return
		}

		const betaTestingCountries =
			process.env.REACT_APP_BETATESTING_COUNTRIES || ''
		const countryMapping = betaTestingCountries
			.split(',')
			.reduce((acc, item) => {
				const [country, status] = item.trim().split(/(?<=^\w\w)/)
				acc[country] = status
				return acc
			}, {})

		const countryStatus = countryMapping[countryCode]

		if (countryStatus === 'openTesting') {
			redirectTo('/mktg/pmuc02')
		} else if (countryStatus === 'closedTesting') {
			redirectTo('/mktg/pmuc03')
		}

		if (sessionStorage.getItem('token') && sessionStorage.getItem('user')) {
			const user = sessionStorage.getItem('user')
			setAuth({
				token: sessionStorage.getItem('token'),
				user: JSON.parse(user)
			})
		}

		setLoading(isLoading)
	}, [setAuth, isLoading, isAllowedCountry, countryCode, history])

	if (loading) {
		return <LoadingIndicator />
	}

	return (
		<Route
			path={path}
			render={({ location }) =>
				auth.token ? (
					<Redirect
						to={{
							pathname: '/dashboard',
							state: { from: location }
						}}
					/>
				) : (
					<>
						<Component {...restProps} />
						{GDPR_COUNTRIES.includes(countryCode) && <ConsentModal />}
					</>
				)
			}
		/>
	)
}

export const LoginRoute = ({ component: Component, path, ...restProps }) => {
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const { isAllowedCountry, countryCode, isLoading } = useGeoLocation()
	const history = useHistory()
	useGtmPageViewTracking(countryCode)
	useEffect(() => {
		if (isLoading) {
			return
		}

		if (!isAllowedCountry) {
			history.push('/mktg/pmuc01')
			setLoading(false)
			return
		}

		const betaTestingCountries =
			process.env.REACT_APP_BETATESTING_COUNTRIES || ''
		const countryMapping = betaTestingCountries
			.split(',')
			.reduce((acc, item) => {
				const [country, status] = item.trim().split(/(?<=^\w\w)/)
				acc[country] = status
				return acc
			}, {})

		const countryStatus = countryMapping[countryCode]

		if (countryStatus === 'closedTesting') {
			history.push('/loginCT')
		}

		if (sessionStorage.getItem('token') && sessionStorage.getItem('user')) {
			const user = sessionStorage.getItem('user')
			setAuth({
				token: sessionStorage.getItem('token'),
				user: JSON.parse(user)
			})
		}

		setLoading(false)
	}, [setAuth, isLoading, isAllowedCountry, countryCode, history])

	if (loading) {
		return <LoadingIndicator />
	}

	return (
		<Route
			path={path}
			render={({ location }) =>
				auth.token ? (
					<Redirect
						to={{
							pathname: '/dashboard',
							state: { from: location }
						}}
					/>
				) : (
					<Component {...restProps} />
				)
			}
		/>
	)
}

export const LoginCTRoute = ({ component: Component, path, ...restProps }) => {
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const { isAllowedCountry, countryCode, isLoading } = useGeoLocation()
	const history = useHistory()

	useGtmPageViewTracking(countryCode)
	useEffect(() => {
		if (isLoading) {
			return
		}

		if (!isAllowedCountry) {
			history.push('/mktg/pmuc01')
			setLoading(false)
			return
		}

		const betaTestingCountries =
			process.env.REACT_APP_BETATESTING_COUNTRIES || ''
		const countryMapping = betaTestingCountries
			.split(',')
			.reduce((acc, item) => {
				const [country, status] = item.trim().split(/(?<=^\w\w)/)
				acc[country] = status
				return acc
			}, {})

		const countryStatus = countryMapping[countryCode]

		if (countryStatus === 'openTesting' || !countryStatus) {
			history.push('/login')
		}

		if (sessionStorage.getItem('token') && sessionStorage.getItem('user')) {
			const user = sessionStorage.getItem('user')
			setAuth({
				token: sessionStorage.getItem('token'),
				user: JSON.parse(user)
			})
		}

		setLoading(false)
	}, [setAuth, isLoading, isAllowedCountry, countryCode, history])

	if (loading) {
		return <LoadingIndicator />
	}

	return (
		<Route
			path={path}
			render={({ location }) =>
				auth.token ? (
					<Redirect
						to={{
							pathname: '/dashboard',
							state: { from: location }
						}}
					/>
				) : (
					<Component {...restProps} />
				)
			}
		/>
	)
}

export const GeoRestrictedRoute = ({
	component: Component,
	path,
	...restProps
}) => {
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const { isAllowedCountry, isLoading, countryCode } = useGeoLocation()
	useGtmPageViewTracking(countryCode)
	const history = useHistory()
	useEffect(() => {
		if (!isLoading && !isAllowedCountry) {
			history.push('/mktg/pmuc01')
		}
		if (sessionStorage.getItem('token') && sessionStorage.getItem('user')) {
			const user = sessionStorage.getItem('user')
			setAuth({
				token: sessionStorage.getItem('token'),
				user: JSON.parse(user)
			})
		}
		setLoading(isLoading)
	}, [setAuth, isLoading, isAllowedCountry, history])

	if (loading) {
		return <LoadingIndicator />
	}

	return (
		<Route
			path={path}
			render={({ location }) =>
				auth.token ? (
					<Redirect
						to={{
							pathname: '/dashboard',
							state: { from: location }
						}}
					/>
				) : (
					<Component {...restProps} />
				)
			}
		/>
	)
}

export const EarlyRegistrationRoute = ({
	component: Component,
	path,
	...restProps
}) => {
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const { isAllowedCountry, countryCode, isLoading } = useGeoLocation()
	const history = useHistory()
	useGtmPageViewTracking(countryCode)

	useEffect(() => {
		if (isLoading) {
			return
		}

		if (isAllowedCountry) {
			const betaTestingCountries =
				process.env.REACT_APP_BETATESTING_COUNTRIES || ''
			const countryMapping = betaTestingCountries
				.split(',')
				.reduce((acc, item) => {
					const [country, status] = item.trim().split(/(?<=^\w\w)/)
					acc[country] = status
					return acc
				}, {})

			const countryStatus = countryMapping[countryCode]

			if (!countryStatus || countryStatus === 'openTesting') {
				history.push('/mktg/pmuc02')
			}
			// For 'closedTesting', do nothing (i.e., don't re-route)
		}

		if (sessionStorage.getItem('token') && sessionStorage.getItem('user')) {
			const user = sessionStorage.getItem('user')
			setAuth({
				token: sessionStorage.getItem('token'),
				user: JSON.parse(user)
			})
		}

		setLoading(false) // Set loading to false once all conditions have been checked
	}, [setAuth, isLoading, isAllowedCountry, countryCode, history])

	if (loading) {
		return <LoadingIndicator />
	}

	return (
		<Route
			path={path}
			render={({ location }) =>
				auth.token ? (
					<Redirect
						to={{
							pathname: '/dashboard',
							state: { from: location }
						}}
					/>
				) : (
					<Component {...restProps} />
				)
			}
		/>
	)
}
